import * as React from "react";
import {
  Card,
  CardBody,
  CardFooter,
  Heading,
  Image,
  Stack,
  Text,
  Button,
  Flex,
  Box,
  VStack,
  Skeleton
} from "@chakra-ui/react";

import { items } from './DataStructs/ShowcaseImages';


interface UserRecProps {
  updateTitle: (title: string) => void;
  updateDescription: (description: string) => void;
  editedImageUrl: string;
  variantImageUrl: string;
  parentMode: "default" | "edit" | "variant";
  openEditor: () => void;
  subImages: { [key: string]: string };
}

export const UserRec = ({ subImages, variantImageUrl, parentMode, updateTitle, updateDescription, editedImageUrl, openEditor }: UserRecProps) => {
  // This is the current index for the images to be displayed. It's initialized as 0.
  const [index, setIndex] = React.useState(0);

  // This map keeps track of which images have loaded. Initially, no images have loaded.
  const [loadedImages, setLoadedImages] = React.useState<{ [key: string]: boolean }>({});

  // This function is called when an image finishes loading. It updates the loadedImages state to mark the image as loaded.
  const handleImageLoad = (key: string) => {
    setLoadedImages(prevState => ({
      ...prevState,
      [key]: true,
    }));
  }

  // This filters the items array to only include items that have a key present in subImages.
  // This ensures that only items which have a corresponding image in subImages are included.
  const filteredItems = items.filter(item => Object.keys(subImages).includes(item.key));



  React.useEffect(() => {
    const intervalId = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % filteredItems.length);
    }, 3000);

    return () => clearInterval(intervalId);
  }, [filteredItems]);

  const currentItem = filteredItems[index] ? { ...filteredItems[index], image: subImages[filteredItems[index].key] || "" } : { key: "", image: "", title: "", description: "" };


  let displayImageUrl = currentItem.image;
  let displayTitle = currentItem.title;
  let displayDescription = currentItem.description;

  if (parentMode === 'edit' && editedImageUrl) {
    displayImageUrl = editedImageUrl;
    displayTitle = "Your Edit";
    displayDescription = ""; // Or set it to the edited description if available
  } else if (parentMode === 'variant' && variantImageUrl) {
    displayImageUrl = variantImageUrl;
    displayTitle = "Your Variant";
    displayDescription = ""; // Or set it to the variant description if available
  }





  const handleButtonClick = () => {
    if (parentMode === 'edit' || parentMode === 'variant') {
      openEditor();
    } else {
      updateTitle(currentItem.title);
      updateDescription(currentItem.description);
    }
  };

  return (
    <Card bg="gray.900" overflow="hidden" borderRadius="20px 0 0 20px">
      <Heading top={0} left={0} mt={2} ml={2} color={"white"} size="md">
        {parentMode !== 'default' ? "Editor" : "Showcase"}
      </Heading>
      <Stack alignItems="center">
        <Heading top={0} left={0} mb={-6} color={"white"} size="md">
          {displayTitle}
        </Heading>
        <CardBody>
          <Flex alignItems="center" justifyContent="center">
            <Box
              width="100%"
              height="400px"  // <-- You can adjust this value based on your requirements
              position="relative"
              border="1px solid"
              borderColor="#000000"
              borderWidth="5px"
            >
              <Skeleton  width="100%"  height="100%" startColor='blue.300' endColor='blue.900' isLoaded={loadedImages[currentItem.key] || false}>
                <Image
                  onLoad={() => handleImageLoad(currentItem.key)}
                  onClick={handleButtonClick}
                  objectFit="cover"
                  src={displayImageUrl}
                  alt={displayTitle}
                  height="100%"
                  cursor="pointer"
                />
              </Skeleton>
            </Box>
          </Flex>
          <Flex
            rounded={40}
            alignItems="center"
            justifyContent="center"
            bg="#000000"
            mt={5}
          >
            <Box width="100%" height="200px" textAlign="center" position="relative">
              <Box position="absolute" top={0} left={4} mt={2} ml={2}></Box>
              <VStack alignItems="center" justifyContent="center" h="100%">
                <Text color="white" as={"b"}>
                  {parentMode !== 'default' ? null : currentItem.description}
                </Text>

                <Button variant="solid" bg={"blue.300"} onClick={handleButtonClick}>
                  {parentMode !== 'default' ? "Open Editor" : "Use Prompt"}
                </Button>
              </VStack>
            </Box>
          </Flex>
        </CardBody>
      </Stack>
    </Card>
  );
};
