import axios from "axios";
import { safePrint } from "./errorHandling";
type EthereumAddress = `0x${string}`;

export const DalleGenerateEdit = async (
    editedBlobData: Blob | null,
    description: string,
    sliderValue: number,
    address: EthereumAddress | undefined

): Promise<any> => {
    safePrint("Dalle edit generation called");
    try {
      const file = new File([editedBlobData!], "image.png", { type: "image/png" });
      const formData = new FormData();

      formData.append('image', file);
      formData.append('prompt', description);
      formData.append('n', sliderValue.toString());
      formData.append('size', "1024x1024");
      formData.append('user', address!.toLowerCase());
      formData.append('response_format', "b64_json");

      const response = await axios.post(
        'https://api.openai.com/v1/images/edits',
        formData,
        {
          headers: {
            'Authorization': `Bearer ${process.env.REACT_APP_API_KEY}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      return response;

    } catch (error) {
      console.error(`Error in generateEdit: ${error}`);
    }
  }

 export const DalleGenerateVariant = async (
    variantBlobData: Blob | null,
    sliderValue: number,
    address: EthereumAddress | undefined
  ): Promise<any> => {
    console.log("Dalle variant generation called");
    try {
      const file = new File([variantBlobData!], "image.png", { type: "image/png" });
      const formData = new FormData();

      formData.append('image', file);
      //formData.append('prompt', description);
      formData.append('n', sliderValue.toString());
      formData.append('size', "1024x1024");
      formData.append('user', address!.toLowerCase());
      formData.append('response_format', "b64_json");

      const response = await axios.post(
        'https://api.openai.com/v1/images/variations',
        formData,
        {
          headers: {
            'Authorization': `Bearer ${process.env.REACT_APP_API_KEY}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      return response; // DALLE returns a list of base64 encoded images

    } catch (error) {
      console.error(`Error in generateVariant: ${error}`);
    }
  }