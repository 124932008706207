import { useState } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  useDisclosure,
  Text,
  Box,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  HStack, 
  Link
} from "@chakra-ui/react";
import { FaTwitter, FaDiscord, FaEnvelope, FaGithub } from "react-icons/fa";
import { ExternalLinkIcon } from '@chakra-ui/icons'

export const FAQModal = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();


  return (
    <>
      <Button
        position="fixed"
        bottom="4"
        right="4"
        onClick={onOpen}
        color="#1DA1F2"
        
      >
        FAQ
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} size="full">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>FAQ</ModalHeader>
          <ModalBody>
          <Box marginBottom="4">
              <Heading size="md">Not showing up on OpenSea?</Heading>
              <Text>
               Please allow for up to 10 minutes for your NFT to show up on OpenSea. If it still does not show up, please try to <Link as='b' href="https://ebutemetaverse.com/how-to-refresh-metadata-on-opensea" isExternal>refresh your metadata.<ExternalLinkIcon mx='2px' /></Link> If all else fails, please contact us on Discord.
              </Text>
            </Box>

          <Box marginBottom="4">
              <Heading size="md">Pricing</Heading>
              <Text>
                We charge $0.020 per image generated and keep the mint fee as low as possible (currently 1 cent) to ensure affordability for our users.
              </Text>
            </Box>

            <Box marginBottom="4">
              <Heading size="md">Why Two Transactions?</Heading>
              <Text>
                We use two transactions to limit bots from abusing our application. By charging the lowest possible fee, we can prevent bots from taking advantage of our service.
              </Text>
            </Box>

            <Box marginBottom="4">
              <Heading size="md">Why Warnings?</Heading>
              <Text>
                Warnings are in place to uphold our moderation policy and ensure that the content generated aligns with OpenAI's guidelines. The following table summarizes the moderated categories:
              </Text>
              <Table variant="simple" mt={4} size="sm">
                <Thead>
                  <Tr>
                    <Th>Category</Th>
                    <Th>Description</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td>Hate</Td>
                    <Td>
                      Content that displays, incites, or endorses hate based on factors such as race, gender, ethnicity, religion, nationality, sexual orientation, disability status, or caste.
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>Hate/Threatening</Td>
                    <Td>
                      Hateful content that involves violence or significant harm directed towards a specific group.
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>Self-harm</Td>
                    <Td>
                      Content that encourages, portrays, or endorses acts of self-harm, including suicide, self-inflicted injuries, and eating disorders.
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>Sexual</Td>
                    <Td>
                      Content intended to provoke sexual arousal, such as descriptions of sexual acts, or promotion of sexual services (excluding sex education and wellness).
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>Sexual/Minors</Td>
                    <Td>
                      Sexual content involving individuals under the age of 18.
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>Violence</Td>
                    <Td>
                      Content that endorses or celebrates violence or the suffering or humiliation of others.
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>Violence/Graphic</Td>
                    <Td>
                      Violent content that portrays death, violence, or severe physical harm in explicit detail.
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
</Box>

<Box marginBottom="4">
              <Heading size="md">Community</Heading>
              <Text>Connect with us on our social channels:</Text>
              <Box display="flex" marginTop="2">
              <a href="https://twitter.com/provenance_ai" target="_blank" rel="noopener noreferrer">
          <FaTwitter size="2em" color="#1DA1F2" style={{ marginRight: '10px' }} />
        </a>
<a href="https://discord.gg/F2Y6cPNa" target="_blank" rel="noopener noreferrer">
          <FaDiscord size="2em" color="#5865F2" style={{ marginRight: '10px' }} />
        </a>
</Box>
</Box>

<Box marginBottom="4">
          <Heading size="md">Support</Heading>
          <Text>
            If you have any questions or need assistance, please see our documentation and feel free to reach out to us:
          </Text>
          <HStack display="flex" marginTop="2" justifyContent="left">
            <a href="mailto:contact@prov.ai" target="_blank" rel="noopener noreferrer">
              <FaEnvelope size="2em" color="#1DA1F2" />
            </a>
            <a href="https://discord.gg/F2Y6cPNa" target="_blank" rel="noopener noreferrer">
          <FaDiscord size="2em" color="#5865F2" style={{ marginRight: '10px' }} />
        </a>
            <a href="https://docs.prov.ai/" target="_blank" rel="noopener noreferrer">
              <FaGithub size="1.90em" color="#000000" />
            </a>
          </HStack> 
        </Box>


<Box marginBottom="4">
          <Heading size="md">View our source code:</Heading>
          <Text mb={'6px'}>
            You can find our open-source code on our GitHub repository. Feel free to take a look, contribute, or provide feedback.
          </Text>
          <a href="https://docs.prov.ai/" target="_blank" rel="noopener noreferrer">
              <FaGithub size="1.90em" color="#000000" />
            </a>
        </Box>

          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              loadingText="Loading"
              onClick={onClose}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
