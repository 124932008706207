import { Dimension, Steps } from "../components/body/DataStructs"
import { safePrint } from "./errorHandling";
import { checkImgDimension, cropImageBehindScenes } from "./imageHandling"

interface GenerationResponse {
    artifacts: Array<{
        base64: string
        seed: number
        finishReason: string
    }>
}

type RequestPayloadType = {
    text_prompts: { text: string }[];
    cfg_scale: number;
    clip_guidance_preset: string;
    height: number;
    width: number;
    samples: number;
    steps: number;
    sampler?: string;
    seed?: number;
    style_preset?: string;
};


export const StableDiffusionGenerate = async (
    prompts: { text: string, weight: number }[],
    dimension: Dimension,
    cfgScale: number,
    clipGuidancePreset: string,
    sliderValue: number,
    steps: Steps,
    sampler: string,
    seed: number,
    stylePreset: string
) => {

    safePrint("Stable Diffusion generation called");
    safePrint(`prompts: , ${prompts}`)

    const [height, width] = dimension.split('x').map(Number);

    const text_prompts = prompts.map(prompt => ({ text: prompt.text, weight: prompt.weight }));

    const requestPayload: RequestPayloadType = {
        text_prompts: text_prompts,
        cfg_scale: cfgScale,
        clip_guidance_preset: clipGuidancePreset,
        height: height,
        width: width,
        samples: sliderValue,
        steps: steps,
    };

    // Include these properties only if they have been set by the user
    if (sampler !== 'NONE') {
        requestPayload.sampler = sampler;
    }

    if (seed !== 0) {
        requestPayload.seed = seed;
    }
    if (stylePreset !== 'NONE') {
        requestPayload.style_preset = stylePreset;
    }

    safePrint(requestPayload)
    console.log(JSON.stringify(requestPayload, null, 2));

    // console.log(requestPayload)

    const engineId = 'stable-diffusion-v1-5';
    const apiHost = 'https://api.stability.ai'; //process.env.REACT_APP_API_HOST ?? 
    const apiKey = process.env.REACT_APP_STABILITY_API_KEY;

    if (!apiKey) throw new Error('Missing Stability API key.');

    const response = await fetch(
        `${apiHost}/v1/generation/${engineId}/text-to-image`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${apiKey}`,
            },
            body: JSON.stringify(requestPayload),
        }
    );


    const responseJSON = (await response!.json()) as GenerationResponse;
    const base64Images: string[] = [];

    responseJSON.artifacts.forEach((image) => {
        base64Images.push(image.base64);
    });

    return {
        data: {
            data: base64Images.map((base64: string) => ({ b64_json: base64 })),
        },
    };
};


export const StableDiffusionGenerateVariant = async (
    variantBlobData: Blob | null,
    prompts: { text: string, weight: number }[],
    cfgScale: number,
    clipGuidancePreset: string,
    sliderValue: number,
    steps: Steps,
    sampler: string,
    seed: number,
    stylePreset: string
) => {
    safePrint("Stable Diffusion Variant called");

    let file = new File([variantBlobData!], "image.png", { type: "image/png" });
    const formData = new FormData();

    if (!(await checkImgDimension(file))) {
        safePrint("Image dimensions are not a multiple of 64");
        const croppedBlob = await cropImageBehindScenes(file);
        file = new File([croppedBlob], "image.png", { type: "image/png" });
    }

    formData.append('init_image', file);
    formData.append('init_image_mode', 'IMAGE_STRENGTH');
    formData.append('cfg_scale', cfgScale.toString());
    formData.append('clip_guidance_preset', clipGuidancePreset);
    formData.append('samples', sliderValue.toString());
    formData.append('steps', steps.toString());

    prompts.forEach((prompt, index) => {
        safePrint(`prompt.text', ${prompt.text}, 'prompt.weight', ${prompt.weight}`);
        formData.append(`text_prompts[${index}][text]`, prompt.text);
        formData.append(`text_prompts[${index}][weight]`, prompt.weight.toString());
    });

    // Include these properties only if they have been set by the user
    if (sampler !== 'NONE') {
        formData.append('sampler', sampler);
    }

    if (seed !== 0) {
        formData.append('seed', seed.toString());
    }
    if (stylePreset !== 'NONE') {
        formData.append('style_preset', stylePreset);
    }

    // For debugging
    Array.from(formData.entries()).forEach(([key, value]) => safePrint(`${key}: ${value}`));


    const engineId = 'stable-diffusion-v1-5';
    const apiHost = 'https://api.stability.ai';
    const apiKey = process.env.REACT_APP_STABILITY_API_KEY;

    if (!apiKey) throw new Error('Missing Stability API key.');

    const response = await fetch(
        `${apiHost}/v1/generation/${engineId}/image-to-image`,
        {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${apiKey}`,
            },
            body: formData,
        }
    );

    if (!response.ok) {
        throw new Error(`Non-200 response: ${await response.text()}`);
    }

    const responseJSON = (await response.json()) as GenerationResponse;
    const base64Images: string[] = [];

    responseJSON.artifacts.forEach((image) => {
        base64Images.push(image.base64);
    });

    return {
        data: {
            data: base64Images.map((base64: string) => ({ b64_json: base64 })),
        },
    };
};


export const StableDiffusionGenerateEdit = async (
    editedBlobData: Blob | null,
    prompts: { text: string, weight: number }[],
    cfgScale: number,
    clipGuidancePreset: string,
    sliderValue: number,
    steps: Steps,
    sampler: string,
    seed: number,
    stylePreset: string,
) => {
    safePrint("Stable Diffusion Edit called");

    let file = new File([editedBlobData!], "image.png", { type: "image/png" });
    const formData = new FormData();

    if (!(await checkImgDimension(file))) {
        console.log("Image dimensions are not a multiple of 64");
        const croppedBlob = await cropImageBehindScenes(file);
        file = new File([croppedBlob], "image.png", { type: "image/png" });
    }

    formData.append('mask_source', 'INIT_IMAGE_ALPHA')
    formData.append('init_image', file);
    formData.append('cfg_scale', cfgScale.toString());
    formData.append('clip_guidance_preset', clipGuidancePreset);
    formData.append('samples', sliderValue.toString());
    formData.append('steps', steps.toString());

    prompts.forEach((prompt, index) => {
        safePrint(`prompt.text', ${prompt.text}, 'prompt.weight', ${prompt.weight}`);
        formData.append(`text_prompts[${index}][text]`, prompt.text);
        formData.append(`text_prompts[${index}][weight]`, prompt.weight.toString());
    });

    // Include these properties only if they have been set by the user
    if (sampler !== 'NONE') {
        formData.append('sampler', sampler);
    }

    if (seed !== 0) {
        formData.append('seed', seed.toString());
    }

    if (stylePreset !== 'NONE') {
        formData.append('style_preset', stylePreset);
    }

    // For debugging
    Array.from(formData.entries()).forEach(([key, value]) => safePrint(`${key}: ${value}`));

    const engineId = 'stable-diffusion-v1-5';
    const apiHost = 'https://api.stability.ai';
    const apiKey = process.env.REACT_APP_STABILITY_API_KEY;

    if (!apiKey) throw new Error('Missing Stability API key.');



    let response: any;

    try {
        response = await fetch(
            `${apiHost}/v1/generation/${engineId}/image-to-image/masking`,
            {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${apiKey}`,
                },
                body: formData,
            }
        );
    } catch (error: any) {
        if (!error.ok && error.status === 500) {
            const errorMessage = await error.message();
            console.error(`Error in generateStableDiffusionEditToImage: ${errorMessage}`);
        } else {
            console.error(`Error in generateStableDiffusionEditToImage: ${error}`);
        }
    }

    const responseJSON = (await response!.json()) as GenerationResponse;
    const base64Images: string[] = [];

    responseJSON.artifacts.forEach((image) => {
        base64Images.push(image.base64);
    });

    return {
        data: {
            data: base64Images.map((base64: string) => ({ b64_json: base64 })),
        },
    };
};