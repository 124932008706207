import React, { useEffect, useState, useMemo, useRef } from "react";
import {
  Modal,
  ModalOverlay,
  useDisclosure,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  Textarea,
  Image as ChakraImage,
  Text,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Flex,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  CloseButton,
  useToast,
  Box,
  Portal,
  Spacer,
  IconButton,
  VStack,
  HStack
} from "@chakra-ui/react";
import axios from "axios";
import env from "react-dotenv";

import { utils } from "ethers";
import { useEthers, useContractFunction, useLogs, useBlockNumber } from "@usedapp/core";
import { useAccount, useProvider, useContractWrite, usePrepareContractWrite, useContractEvent } from 'wagmi'
import { prepareWriteContract, writeContract, waitForTransaction } from '@wagmi/core';
import { Contract } from "@ethersproject/contracts";
import { abi } from "../../abis";
import { OpenSeaModal } from "./OpenSeaModal";
import { useContract, useWaitForTransaction } from 'wagmi'
import useDebounce from './hooks/Debounce';
import { watchContractEvent } from '@wagmi/core'
import { createPublicClient, http, parseAbiItem, PublicClient } from 'viem'
import { mainnet } from 'viem/chains'
import { set } from "cypress/types/lodash";

import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import { Prompt } from "./DataStructs/"

//https://dev.to/cormacncheese/how-to-send-ether-or-matic-using-ethers-4fjo

type EthereumAddress = `0x${string}`;
const contractAddress = process.env.REACT_APP_ADDRESS as EthereumAddress;

const pinataApiKey = process.env.REACT_APP_PINATA_API_KEY || "";
const pinataApiSecret = process.env.REACT_APP_PINATA_SECRET || "";

interface Props {
  sliderValue: number;
  prompts: Prompt[];
  model: string;
  imgData: Blob[];
  imgURL: string[];
  title: string;
  description: string;
  openEditor: () => void;
  setOriginalImageUrl: (image: HTMLImageElement | null) => void;
  image: HTMLImageElement | null;
  setVariantImageUrl: (url: string) => void;
  setParentMode: (mode: "default" | "edit" | "variant") => void;

}





export const RequestModal = (props: Props) => {
  // https://www.johno.com/react-hooks-stale-state
  const { address } = useAccount()
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isRegenPopoverOpen, setIsRegenPopoverOpen] = useState(false);
  const { account, library } = useEthers();
  const [loading, setLoading] = useState<boolean>(false);
  const [openSeaLink, setopenSeaLink] = useState<string>("");
  const [myTokenURI, setTokenURI] = useState<string>("");
  const [ipfsHash, setipfsHash] = useState<string>("");
  const [ipfsHashData, setipfsHashData] = useState<string>("");
  const [myStatus, setMyStatus] = useState<{ status: string, errorMessage?: string }>({ status: "" });
  const ref = React.useRef(null);
  // useState dataTx type any 
  const [dataTx, setDataTx] = useState<any>();

  const myTokenURIref = useRef("");
  const debouncedmyTokenURI = useDebounce(myTokenURI, 500)


  const isProd = process.env.REACT_APP_IS_PROD === 'true';

  const alchemyPolyURL = `https://polygon-mainnet.g.alchemy.com/v2/${process.env.REACT_APP_ALCHEMY_API_KEY_POLY}`
  const alchemyMumbaiURL = `https://polygon-mumbai.g.alchemy.com/v2/${process.env.REACT_APP_ALCHEMY_API_KEY_MUMBAI}`
  const transport = http(isProd ? alchemyPolyURL : alchemyMumbaiURL)

  const client = createPublicClient({
    chain: mainnet,
    transport,
  })

  const openInEditor = (imageUrl: string) => {
    //props.setEditedImageUrl(imageUrl);
    props.setParentMode("variant");
    const img = new Image();
    img.src = imageUrl!;
    img.onload = () => props.setOriginalImageUrl(img);
    props.setVariantImageUrl(imageUrl);
    props.openEditor();
    onClose();
  }

  // Define the ref
  const tabListRef = useRef<HTMLDivElement>(null);

  // Function to scroll the tab list to the left
  const scrollLeft = () => {
    if (tabListRef.current) {
      tabListRef.current.scrollLeft -= 200; // Change this value to scroll more/less
    }
  };

  // Function to scroll the tab list to the right
  const scrollRight = () => {
    if (tabListRef.current) {
      tabListRef.current.scrollLeft += 200; // Change this value to scroll more/less
    }
  };







  const mintPrice = utils.parseEther('0.01');





  const NFTinterface = new utils.Interface(abi);
  // console.log("NFTinterface", NFTinterface);
  const NFTinterfaceAddress = contractAddress;
  // console.log("NFTinterfaceAddress", NFTinterfaceAddress);
  //const NFTcontract = new Contract(NFTinterfaceAddress, NFTinterface);
  //https://www.freecodecamp.org/news/what-every-react-developer-should-know-about-state/#:~:text=What%20is%20stale%20state%20in,variable%20from%20an%20outer%20scope.

  // console.log("NFTcontract", NFTcontract);

  const provider = useProvider()
  const NFTcontract = new Contract(NFTinterfaceAddress, NFTinterface, provider);

  const GetLogs = async () => {

    const isProd = process.env.REACT_APP_IS_PROD === 'true';

    const filter = NFTcontract.filters.NFTMinted(address);
    const logs = await NFTcontract.queryFilter(filter, -1, 'latest');
    // console.log("RequestModal logs", logs);

    const tokenIds: number[] = [];
    logs?.forEach((log) => {
      tokenIds.push(log?.args?.tokenId.toNumber());
      const OpenSeaLink = isProd ? `https://opensea.io/assets/matic/${contractAddress}/${log?.args?.tokenId.toString()}` : `https://testnets.opensea.io/assets/mumbai/${contractAddress}/${log?.args?.tokenId.toString()}`;
      setopenSeaLink(OpenSeaLink);
    });

  }

  // const { isLoading, isSuccess, status } = useWaitForTransaction({
  //   hash: data?.hash,
  //   onSuccess() {
  //       GetLogs();
  //       setLoading(false);
  //   },s
  // })



  interface ModalProps {
    imageUrls: string[];
  }

  const toast = useToast();

  const mintNFT = async (URI: string) => {
    try {
      setMyStatus({ status: "loading" });

      if (!URI) {
        console.error('tokenURI is null:', URI);
        setLoading(false);
        throw new Error('tokenURI is null');
      }

      // console log URI
      // console.log("URI!", URI);
      const config = await prepareWriteContract({
        address: contractAddress,
        abi: abi,
        functionName: 'mint',
        args: [URI],
        overrides: {
          value: mintPrice
        }
      });

      const { hash } = await writeContract(config);
      const data = await waitForTransaction({
        hash,
      })

      // log the data
      // console.log('waitForTransaction:', data);
      // set setDataTx 
      setDataTx(data);



    } catch (error: any) {
      console.error('ERROR:', error);
      setMyStatus({ status: "error", errorMessage: error.message });
    }
  };




  // useEffect setDataTx
  useEffect(() => {
    const fetchLogs = async () => {
      setMyStatus({ status: "success" });
      await GetLogs();
      setLoading(false);
    };

    fetchLogs();

    //return () => unwatch();
  }, [dataTx]);


  // const { isLoading, isSuccess, status } = useWaitForTransaction({
  //   hash: dataTx?.hash,
  //   onSuccess() {
  //       GetLogs();
  //       setLoading(false);
  //   },
  // })

  useEffect(() => {
    if (myStatus.status === "loading") {
      toast({
        position: "top-right",
        title: "Validating Transaction...",
        status: "info",
        duration: 9000,
        isClosable: true,
      });
      // } else if (state.status === "PendingSignature") {
      //   toast({
      //     position: "top-right",
      //     title: "Check Wallet: Waiting for signature...",
      //     status: "warning",
      //     duration: 9000,
      //     isClosable: true,
      //   });test
    } else if (myStatus.status === "success") {
      toast({
        position: "top-right",
        title: "Transaction successful!",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } else if (myStatus.status === "error") {
      toast({
        position: "top-right",
        title: "Error, Please try again",
        description: myStatus.errorMessage,
        status: "error",
        duration: 9000,
        isClosable: true,
      });

      if (myStatus.errorMessage == "User rejected request") {
        unpinFromIPFS(ipfsHash);//ipfsHash
        unpinFromIPFS(ipfsHashData);//ipfsHash
        setLoading(false);
      }

    }
  }, [myStatus]);

  const metadataTemplate = {
    name: "",
    description: "",
    image: "",
  }


  /**
 * Unpins an IPFS hash from pinata if user rejects the transaction.
 *
 * @param {string} ipfsHash - The IPFS hash to unpin.
 * @returns {Promise<void>} Returns a promise that resolves when the unpinning operation is completed.
 */
  const unpinFromIPFS = async (ipfsHash: string) => {
    try {
      await axios({
        method: 'delete',
        url: `https://api.pinata.cloud/pinning/unpin/${ipfsHash}`,
        headers: {
          pinata_api_key: pinataApiKey,
          pinata_secret_api_key: pinataApiSecret,
        },
      });
      console.log('Unpinned from IPFS:', ipfsHash);
    } catch (error) {
      console.error('Error unpinning from IPFS:', error);
    }
  };

  // NEED TO UNPIN IF REJECTED
  // https://docs.pinata.cloud/pinata-api/pinning/remove-files-unpin

  /**
 * Pins the actual image to IPFS.
 *
 * @param {Blob} imageUrl - The image to pin.
 * @returns {Promise<string>} Returns a promise that resolves with the IPFS URL of the pinned image.
 */
  const pinImageToIPFS = async (imageUrl: Blob): Promise<string> => {

    console.log("Pinning image to IPFS");

    const myFile = new File([imageUrl], `${address}.png`);
    // console.log("myFile", myFile);

    let pinataResponse;

    try {

      const formData = new FormData();

      formData.append("file", myFile);
      //console.log('formData:', formData);
      // console.log('key:', process.env.REACT_APP_PINATA_API_KEY);
      // console.log('secret:', process.env.REACT_APP_PINATA_SECRET);
      // DUmmy Commit

      pinataResponse = await axios({
        method: "post",
        url: "https://api.pinata.cloud/pinning/pinFileToIPFS",
        data: formData,
        headers: {
          pinata_api_key: pinataApiKey,
          pinata_secret_api_key: pinataApiSecret,
          'Content-Type': `multipart/form-data`,
        },

      });

    } catch (error) {
      console.log("Error pinning image to IPFS")
    }
    console.log("pinataResponse", pinataResponse);
    setipfsHashData(pinataResponse?.data.IpfsHash);

    const ipfsURL = `ipfs://${pinataResponse?.data.IpfsHash}`;


    //await updateMetadata(pinataResponse?.data.IpfsHash, metadata);


    return ipfsURL;

  };


  /**
 * Pins the actual metadata to IPFS.
 *
 * @param {Blob} imageURL - The image for the token.
 * @param {string} title - The title of the token.
 * @param {string} description - The description of the token.
 * @returns {Promise<string>} Returns a promise that resolves with the token URI.
 */
  async function handleTokenUris(imageURL: Blob, title: string, description: string) {
    setLoading(true);
    const ipfsURL = await pinImageToIPFS(imageURL)
    let pinataResponse;
    let NFTuri;
    let tokenUriMetadata = { ...metadataTemplate }
    tokenUriMetadata.name = title
    tokenUriMetadata.description = description
    tokenUriMetadata.image = `${ipfsURL}`
    // log the metadata


    const formData = new FormData();
    formData.append('file', JSON.stringify(tokenUriMetadata));


    const options = {
      pinataMetadata: {
        name: tokenUriMetadata.name,
      },
    }

    // ERROR HANDLE THIS
    try {
      pinataResponse = await axios({
        method: "post",
        url: "https://api.pinata.cloud/pinning/pinJSONToIPFS",
        data: JSON.stringify(tokenUriMetadata),
        headers: {
          pinata_api_key: pinataApiKey,
          pinata_secret_api_key: pinataApiSecret,
          'Content-Type': 'application/json',
        },


      });
      // console.log("pinataResponse: ", pinataResponse.data.IpfsHash);
      NFTuri = `ipfs://${pinataResponse?.data.IpfsHash}`;
      setipfsHash(pinataResponse?.data.IpfsHash);
      // console.log("Seeting URI...", NFTuri);
      setTokenURI(NFTuri as string);

    } catch (error) {
      console.log("Error pinning image to IPFS")
      console.log(error)
    }
    return NFTuri;

  }



  /**
 * Handles the minting of a token.
 *
 * @param {Blob} imageData - The image data for the token.
 * @param {string} title - The title of the token.
 * @param {string} description - The description of the token.
 * @returns {Promise<void>} Returns a promise that resolves when the minting operation is completed.
 */
  async function handleMint(imageData: Blob, title: string, description: string) {

    let useDescription = description;

    if (props.model === 'Stable Diffusion' && props.prompts.length > 1) {
      useDescription = props.prompts.map(prompt => prompt.text).join(' ');
      console.log("useDescription", useDescription);
    }

    try {
      const tokenURI = await handleTokenUris(imageData, title, useDescription);
      // setTokenURI(tokenURI as string);
      await mintNFT(tokenURI as string);
    } catch (error) {
      console.error("Error in handleMint:", error);
    } finally {
      //setLoading(false);
    }
  }


  /**
 * Downloads a file from a URL.
 *
 * @param {string} url - The URL of the file to download.
 * @param {string} filename - The filename to use for the downloaded file.
 */
  const handleDownload = (url: string, filename: string) => {
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };


  useEffect(() => {
    if (props.imgURL) {
      onOpen();
    }
  }, [props.imgURL]);

  interface EditorAndDownloadPopoverProps {
    imageUrl: string;
  }

  const EditorAndDownloadPopover: React.FC<EditorAndDownloadPopoverProps> = ({ imageUrl }) => {


    return (
      <Box
        sx={{
          "& .chakra-popover__popper": {
            zIndex: "popover"
          }
        }}
      >
        <Popover>
          <PopoverTrigger>
            <Button variant="ghost" color='white'>
              Open in Editor
            </Button>
          </PopoverTrigger>
          <Portal containerRef={ref}>
            <PopoverContent>
              <PopoverArrow />
              <PopoverHeader>Attention</PopoverHeader>
              <PopoverCloseButton />
              <PopoverBody>
                If you generated multiple images, please note that ONLY the image you select to open in the editor will be saved. <Text as={'b'}>Any other images will be lost.</Text> We recommend downloading any additional images you like before proceeding to the editor.
              </PopoverBody>
              <PopoverFooter>
                <Flex justifyContent="space-between">
                  <Button colorScheme="blue" onClick={() => openInEditor(imageUrl)}>Open in Editor</Button>
                  <Button colorScheme="green" onClick={() => handleDownload(imageUrl, 'Provenance')}>Download</Button>
                </Flex>
              </PopoverFooter>
            </PopoverContent>
          </Portal>
        </Popover>
      </Box>
    );
  }



  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} >
      <ModalOverlay />
      <ModalContent ref={ref} background="gray.800">
        <Popover placement='bottom' isOpen={isPopoverOpen}>
          <PopoverTrigger>
            <CloseButton color={'white'} onClick={() => { setIsPopoverOpen(true) }} position="absolute" top={2} right={2} />
          </PopoverTrigger>
          <PopoverContent>
            <PopoverArrow />
            <PopoverHeader color={'black'}>Confirm Close</PopoverHeader>
            <PopoverBody>
              Are you sure you want to close this modal? Any non-minted/downloaded images will be lost.
            </PopoverBody>
            <PopoverFooter>
              <Button color={'white'} bg="blue.300" mr={3} onClick={() => { setIsPopoverOpen(false); onClose(); }}>
                Yes, Close
              </Button>
              <Button variant="ghost" onClick={() => { setIsPopoverOpen(false) }}>
                Cancel
              </Button>
            </PopoverFooter>
          </PopoverContent>
        </Popover>
        <ModalHeader color={'white'}>
          Generated Images
        </ModalHeader>

        <ModalBody >
          <Tabs isFitted variant='enclosed' size='md'>
            <HStack width={'100%'}>
              <TabList
                width={props.sliderValue > 5  ? "80%": "100%"}
                as={'b'}
                ref={tabListRef}
                css={{
                  overflowY: "auto",
                  msOverflowStyle: "none", // For Internet Explorer and Edge
                  scrollbarWidth: "none", // For Firefox
                  "&::-webkit-scrollbar": {
                    display: "none", // For Chrome, Safari, and Opera
                  },
                }}>
                {props.imgURL && props.imgURL.map((_: any, index: number) => (
                  <Tab color={'white'} _selected={{ color: 'white', bg: 'blue.300' }} key={index}>Image {index + 1}</Tab>
                ))}
              </TabList>
              {props.sliderValue > 5 &&
                <HStack width={'20%'} justifyContent="center">
                  {/*ARROWS TO MOVE TABS HERE */}
                  <IconButton
                    aria-label={"left"}
                    icon={<FaArrowLeft />}
                    onClick={scrollLeft}
                    color={'white'}
                    bg={'transparent'}
                  />
                  <IconButton
                    aria-label={"right"}
                    icon={<FaArrowRight />}
                    onClick={scrollRight}
                    color={'white'}
                    bg={'transparent'}
                  />
                </HStack>
              }
            </HStack>
            <TabPanels >
              {props.imgURL && props.imgURL.map((imageUrl: string, index: number) => (
                <TabPanel key={index} >
                  <ChakraImage mt={"20px"} width={"350px"} src={imageUrl} />
                  <Flex mt={2} justifyContent="space-between">
                    <EditorAndDownloadPopover imageUrl={imageUrl} />
                    <Flex alignItems="center">
                      <Box width="100px" height="40px">
                        <Button
                          variant="ghost"
                          color={'white'}
                          w="100%"
                          h="100%"
                          onClick={() => handleDownload(imageUrl, 'Provenance')}
                        >
                          Download
                        </Button>
                      </Box>
                      <Spacer />
                      <Box width="100px" height="40px">
                        <Button
                          color={'white'}
                          bg="blue.300"
                          w="100%"
                          h="100%"
                          isLoading={loading}
                          loadingText={'Minting...'} //myStatus.status === "loading" ? 'Check Wallet......' : 'Minting...'}
                          onClick={async () => {
                            await handleMint(props.imgData[index], props.title, props.description);
                          }}
                        >
                          Mint
                        </Button>
                      </Box>
                    </Flex>
                  </Flex>
                </TabPanel>
              ))}
              {openSeaLink && <OpenSeaModal openSeaLink={openSeaLink} clearLink={() => setopenSeaLink("")} />}
            </TabPanels>
          </Tabs>
        </ModalBody>

        <ModalFooter>
        </ModalFooter>
      </ModalContent>
    </Modal >
  )
}