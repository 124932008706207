import React, { useEffect, useState } from "react";
import { Configuration, OpenAIApi } from 'openai';
import {
  Box,
  VStack,
  Flex,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Button,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Textarea,
  useToast,
  UnorderedList,
  ListItem,
  Select,
  HStack
} from "@chakra-ui/react"
import { RequestModal } from "./RequestModal";
// IMPORT COMPONENTS
import { FAQModal } from "./FAQ"
import { UserRec } from "./Recommend";
import { utils, BigNumber } from "ethers";


import { TESTING_ENABLED, hardhatConfig, mumbaiConfig } from "../../config";
import { useAccount, useContractWrite, usePrepareContractWrite } from 'wagmi'
import { fetchBalance } from '@wagmi/core';
import { useWaitForTransaction } from 'wagmi'
import { Analytics } from "aws-amplify";
import useDebounce from './hooks/Debounce';
import DalleEdits from './DalleEdits';
import AdvancedOptions from './AdvancedOptions';
import './App.css';
import { Dimension, Steps, ALL_MODERATION_CATEGORIES } from "./DataStructs/"
import { convertToBlobs, safePrint, getMATICPriceFromCoinGecko, getMATICPriceFromEtherscan, recordEvent, StableDiffusionCalcCost, DalleGenerateEdit, DalleGenerateVariant, StableDiffusionGenerate, StableDiffusionGenerateVariant, StableDiffusionGenerateEdit } from '../../helpers/';
// (window as any).LOG_LEVEL = 'DEBUG';
// to enable Analytics
const ENABLE_GENERATION = true;





const env_config = TESTING_ENABLED ? hardhatConfig : mumbaiConfig;
const chainID = process.env.REACT_APP_IS_PROD === 'false' ? 80001 : 137;

//Dummy Commit
const apiKey = process.env.REACT_APP_API_KEY;


type EthereumAddress = `0x${string}`;

const contractAddress = process.env.REACT_APP_ADDRESS as EthereumAddress;

const config = new Configuration({
  apiKey: apiKey,
});

const openai = new OpenAIApi(config);

class PayingGenerationFeeError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'Paying Generation Fee Error';
  }
}

interface Props {
  prompts: { text: string, weight: number }[],
  setPrompts: (prompts: { text: string, weight: number }[]) => void;
  title: string;
  description: string;
  setDescription: (url: string) => void;
  setTitle: (url: string) => void;
  editedImageUrl: string;
  setEditedImageUrl: (url: string) => void;
  editedBlobData: Blob | null;
  setEditedBlobData: (blob: Blob | null) => void;
  variantImageUrl: string;
  setVariantImageUrl: (url: string) => void;
  variantBlobData: Blob | null;
  setVariantBlobData: (blob: Blob | null) => void;
  parentMode: "default" | "edit" | "variant";
  setParentMode: (mode: "default" | "edit" | "variant") => void;
  model: "Dalle" | "Stable Diffusion";
  setModel: (model: "Dalle" | "Stable Diffusion") => void;
  isModalOpen: boolean;
  setModalOpen: (isOpen: boolean) => void;
  closeModal: () => void;
  originalImageUrl: HTMLImageElement | null;
  setOriginalImageUrl: (image: HTMLImageElement | null) => void;
  setImages: (images: { [key: string]: string }) => void;
  images: { [key: string]: string };
  subImages: { [key: string]: string };
  dimension: Dimension;
  setDimension: (dimensions: Dimension) => void;
  PromptWeight: number;
  setPromptWeight: (weight: number) => void;
  ImageWeight: number;
  setImageWeight: (weight: number) => void;
  cfgScale: number;
  setCfgScale: (scale: number) => void;
  clipGuidancePreset: string;
  setClipGuidancePreset: (preset: string) => void;
  sampler: string;
  setSampler: (sampler: string) => void;
  seed: number;
  setSeed: (seed: number) => void;
  steps: number;
  setSteps: (steps: Steps) => void;
  stylePreset: string;
  setStylePreset: (preset: string) => void;
  sliderValue: number;
  setSliderValue: (value: number) => void;

}


/**
 * ImageGeneration component. It allows you to generate, edit, and view images.
 *
 * @component
 * @param {Object} props - Component props
 * @param {string[]} props.prompts - Array of prompts used for image generation
 * @param {Function} props.setPrompts - Function to set prompts
 * @param {HTMLImageElement[]} props.subImages - Sub images used for generation
 * @param {string} props.parentMode - Mode of operation (default, variant, edit)
 * @param {Function} props.setParentMode - Function to set mode of operation
 * @param {string} props.editedImageUrl - URL of the edited image
 * @param {Function} props.setEditedImageUrl - Function to set the URL of the edited image
 * @param {Blob} props.editedBlobData - Blob data of the edited image
 * @param {Function} props.setEditedBlobData - Function to set the blob data of the edited image
 * @param {string} props.variantImageUrl - URL of the variant image
 * @param {Function} props.setVariantImageUrl - Function to set the URL of the variant image
 * @param {Blob} props.variantBlobData - Blob data of the variant image
 * @param {Function} props.setVariantBlobData - Function to set the blob data of the variant image
 * @param {string} props.title - Title of the image
 * @param {Function} props.setTitle - Function to set the title of the image
 * @param {string} props.description - Description of the image
 * @param {Function} props.setDescription - Function to set the description of the image
 * @param {boolean} props.isModalOpen - Indicates if the modal is open
 * @param {Function} props.setModalOpen - Function to set the state of the modal
 * @param {Function} props.closeModal - Function to close the modal
 * @param {string} props.originalImageUrl - URL of the original image
 * @param {Function} props.setOriginalImageUrl - Function to set the URL of the original image
 * @param {string} props.model - The model used for generation
 * @param {Function} props.setModel - Function to set the model
 * @param {number} props.dimension - Dimension of the image
 * @param {Function} props.setDimension - Function to set the dimension of the image
 * @param {number} props.PromptWeight - Weight of the prompt used in the image generation
 * @param {Function} props.setPromptWeight - Function to set the weight of the prompt
 * @param {number} props.ImageWeight - Weight of the image used in the generation
 * @param {Function} props.setImageWeight - Function to set the weight of the image
 * @param {number} props.cfgScale - Config scale used in the generation
 * @param {Function} props.setCfgScale - Function to set the config scale
 * @param {string} props.clipGuidancePreset - Clip guidance preset used in the generation
 * @param {Function} props.setClipGuidancePreset - Function to set the clip guidance preset
 * @param {string} props.sampler - Sampler used in the generation
 * @param {Function} props.setSampler - Function to set the sampler
 * @param {number} props.seed - Seed used in the generation
 * @param {Function} props.setSeed - Function to set the seed
 * @param {number} props.steps - Steps used in the generation
 * @param {Function} props.setSteps - Function to set the steps
 * @param {string} props.stylePreset - Style preset
 * 
 * @returns {JSX.Element} PromptBuilder component.
 */
export const ImageGeneration = ({
  prompts,
  setPrompts,
  subImages,
  parentMode,
  setParentMode,
  editedImageUrl,
  setEditedImageUrl,
  editedBlobData,
  setEditedBlobData,
  variantImageUrl,
  setVariantImageUrl,
  variantBlobData,
  setVariantBlobData,
  title,
  setTitle,
  description,
  setDescription,
  isModalOpen,
  setModalOpen,
  closeModal,
  originalImageUrl,
  setOriginalImageUrl,
  model,
  setModel,
  dimension,
  setDimension,
  PromptWeight,
  setPromptWeight,
  ImageWeight,
  setImageWeight,
  cfgScale,
  setCfgScale,
  clipGuidancePreset,
  setClipGuidancePreset,
  sampler,
  setSampler,
  seed,
  setSeed,
  steps,
  setSteps,
  stylePreset,
  setStylePreset,
  sliderValue,
  setSliderValue


}: Props) => {
  const { address } = useAccount()
  // const [result, setResult] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const [imageUrl, setImageUrl] = useState<string[]>([]);
  const [blobData, setBlobData] = useState<Blob[]>([]);
  const [image, setImage] = useState<HTMLImageElement | null>(null);

  // const [options, setOptions] = useState();
  const [isAdvancedOptionsOpen, setAdvancedOptionsOpen] = useState(false);


  const [MATICPriceInUSD, setMATICPriceInUSD] = useState(null);


  const debouncedmysliderValue = useDebounce(sliderValue, 500)

  const [flaggedCategories, setFlaggedCategories] = useState<string[]>([]);
  const [canPayFee, setCanPayFee] = useState<boolean>(false);


  const [apiFee, setApiFee] = useState<BigNumber>(BigNumber.from(0));



  //const [model, setModel] = useState('Dalle');

  Analytics.autoTrack('session', {
    enable: true,
    attributes: {
      description: description,
      title: title,
      parentMode: parentMode
    },
    provider: 'AWSPinpoint'
  });

  /**
 * Handles the opening of advanced options modal.
 */
  const handleAdvancedOptionsOpen = () => {
    setAdvancedOptionsOpen(true);
  };

  /**
 * Handles the closing of advanced options modal.
 */
  const handleAdvancedOptionsClose = () => {
    setAdvancedOptionsOpen(false);
  };

/**
 * Get the balance of the user's Ethereum address. 
 *
 * @param {EthereumAddress} address - Ethereum address of the user.
 * @param {number} TotalFeeInMATIC - The total fee in MATIC.
 * @returns {Promise<boolean>} Returns a promise that resolves to a boolean 
 * indicating whether the user has sufficient balance to pay the fee.
 */
  const getBalance = async (address: EthereumAddress, TotalFeeInMATIC: number) => {
    console.log("address", address);


    const { formatted } = await fetchBalance({
      address: address,
      token: '0x0000000000000000000000000000000000001010',
      chainId: chainID, // 80001,
    })

    safePrint(`chainID: ${chainID}`)
    setCanPayFee(Number(formatted) > TotalFeeInMATIC ? true : false);
    return Number(formatted) > TotalFeeInMATIC ? true : false;
  }

  useEffect(() => {
    const fetchMATICPrice = async () => {
      if (!MATICPriceInUSD) {
        let price = await getMATICPriceFromCoinGecko();
        if (!price) {
          console.log('API #1 failed, trying #2 API...');
          price = await getMATICPriceFromEtherscan();
        }
        if (!price) {
          console.log('API #2 failed, trying #3 API...');
          price = 1; // default price
        }
        setMATICPriceInUSD(price);
      }
    };

    fetchMATICPrice();
  }, []);


/**
 * Calculate the fee per image based on the model used.
 *
 * @returns {number} Returns the calculated fee per image in ETH.
 */
  const calculateFeePerImage = () => {
    let feePerImageInUSD;

    switch (model) {
      case "Dalle":
        feePerImageInUSD = 0.018;
        break;

      case "Stable Diffusion":
        feePerImageInUSD = StableDiffusionCalcCost(dimension, steps as Steps);
        break;
    }

    if (!MATICPriceInUSD) {
      safePrint("ERROR: MATICPriceInUSD is null")
      return 0;
    }

    const feePerImageInETH = feePerImageInUSD / MATICPriceInUSD;
    return feePerImageInETH;
  };

  //TotalFeeInMATIC, setTotalFeeInMATIC
  useEffect(() => {
    const getPriceData = async () => {
      const feePerImageInETH = calculateFeePerImage();
      const totalFeeInETH = feePerImageInETH * sliderValue;
      //TotalFeeInMATIC, setTotalFeeInMATIC
      console.log('Total fee in MATIC:', totalFeeInETH);
      console.log('getBalance:', await getBalance(address!, totalFeeInETH));
      const calculatedApiFee = utils.parseEther(totalFeeInETH.toFixed(18));
      setApiFee(calculatedApiFee);
    }
    getPriceData();

  }, [address, sliderValue, model, dimension, steps]); //Model 


  // PREP CONTRACT OBJECT

  const { config } = usePrepareContractWrite({
    address: contractAddress,
    abi: env_config.nftInterface,
    functionName: 'imageGenerationPayment',
    args: [apiFee],
    enabled: Boolean(debouncedmysliderValue),
    overrides: {
      value: apiFee
    }
  })

  const { data, write } = useContractWrite({
    ...config,
    onError: (error) => {
      setLoading(false);
      toast({
        position: "top-right",
        title: "Error, Please try again",
        description: error.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    },
  });

  const { isLoading, isSuccess, isError, status } = useWaitForTransaction({
    confirmations: 2,
    hash: data?.hash,
  })

  // call generateImage() status === 'success' using useEffect
  useEffect(() => {
    if (status === 'success') {
      const callGenImage = async () => {
        try {
          if (ENABLE_GENERATION) {
            await generateImage(status);
          }
          setLoading(false);
        } catch (error: any) {
          console.error("Error Calling API:", error);
        }
      }
      callGenImage();
    }
  }, [status])


  /**
 * Initiates the payment of the API fee.
 */
  const payApiFee = async () => {
    setLoading(true); // ERROR EDGE CASE HEREß

    try {
      write?.()

    } catch (error) {
      console.error('Custom ERROR listener:', error);
    }// finally {
    //   setLoading(false);
    // }
  };



  const toast = useToast();

  useEffect(() => {
    if (isLoading) {
      toast({
        position: "top-right",
        title: "Validating Transaction...",
        status: "info",
        duration: 9000,
        isClosable: true,
      });
      // } else if (status === "idle") {
      //   toast({
      //     position: "top-right",
      //     title: "Check Wallet: Waiting for signature...",
      //     status: "warning",
      //     duration: 9000,
      //     isClosable: true,
      //   });
    }
    else if (isSuccess) {
      toast({
        position: "top-right",
        title: "Transaction successful!",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } else if (isError) {
      toast({
        position: "top-right",
        title: "Error, Please try again",
        //description: status.errorMessage,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      setLoading(false);
    }
  }, [status]);

  useEffect(() => {
    if (flaggedCategories.length > 0) {
      toast({
        position: "bottom",
        title: "Text flagged for moderation",
        description: (
          <Box>
            <p>The following moderation categories were violated:</p>
            <UnorderedList pl={4}>
              {flaggedCategories.map((category) => (
                <ListItem key={category}>{category}</ListItem>
              ))}
            </UnorderedList>
          </Box>
        ),
        status: "error",
        duration: 10000,
        isClosable: true,
      });
    }
  }, [flaggedCategories]);

  /**
 * Moderation function to moderate the description of the image.
 *
 * @param {string} description - Description of the image.
 * @returns {Promise<string[]>} Returns a promise that resolves to an array of 
 * strings which are flagged categories.
 */
  const moderation = async (description: string): Promise<string[]> => {

    const response = await openai.createModeration({
      input: description,
    });

    const categories = response.data.results[0].categories as any;

    const flaggedCategories = Object.keys(categories).filter(
      (category) => categories[category]
    );

    return flaggedCategories;
  };



  /**
 * Generate the image based on various conditions.
 *
 * @param {string} status - Status of the request.
 * @returns {Promise<void>} Returns a promise that resolves when the image 
 * generation is completed.
 */
  const generateImage = async (status: string): Promise<void> => {

    if (status !== "success") {
      throw new PayingGenerationFeeError('It appears you did not pay the Generaiton fee. if you believe this is a mistake please contact us via discord or email');
    }

    // ENFORCE MODERATION
    try {
      const flaggedCategories = await moderation(description);
      if (flaggedCategories.length > 0) {
        console.log("Text flagged for moderation. Categories:", flaggedCategories);
        setFlaggedCategories(flaggedCategories);
        setLoading(false);
        return;
      }
    } catch (error) {
      console.error("Error calling createModeration:", error);
      setLoading(false);
      return;
    }

    try {
      var res: any;
      // IF EDIT MODE generateStableDiffusion
      if (model === "Stable Diffusion" && parentMode === 'default') {
        try {
        res = await StableDiffusionGenerate(
          prompts,
          dimension,
          cfgScale,
          clipGuidancePreset,
          sliderValue,
          steps as Steps,
          sampler,
          seed,
          stylePreset,
        );
        } catch (error) {
          toast({
            position: "top-right",
            title: "Error code: 0001",
            description: "Please see our 'Support' section in the FAQ and reach out with the error code.",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        }
      } else if (model === "Stable Diffusion" && parentMode === 'variant') {
        try {
        res = await StableDiffusionGenerateVariant(
          variantBlobData,
          prompts,
          cfgScale,
          clipGuidancePreset,
          sliderValue,
          steps as Steps,
          sampler,
          seed,
          stylePreset,
        );
        } catch (error) {
          console.log(error);
          toast({
            position: "top-right",
            title: "Error code: 0002",
            description: "Please see our 'Support' section in the FAQ and reach out with the error code.",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        }
      } else if (model === "Stable Diffusion" && parentMode === 'edit') {
        //generateStableDiffusionEditToImage
        try {
        res = await StableDiffusionGenerateEdit(
          editedBlobData,
          prompts,
          cfgScale,
          clipGuidancePreset,
          sliderValue,
          steps as Steps,
          sampler,
          seed,
          stylePreset
        );
        } catch (error) {
          toast({
            position: "top-right",
            title: "Error code: 0003",
            description: "Please see our 'Support' section in the FAQ and reach out with the error code.",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        }
      }
      else if (parentMode === "edit") {
        try {
        res = await DalleGenerateEdit(editedBlobData, description, sliderValue, address);
        } catch (error) {
          toast({
            position: "top-right",
            title: "Error code: 0004",
            description: "Please see our 'Support' section in the FAQ and reach out with the error code.",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        }
      }
      // IF VARIANT MODE
      else if (parentMode === "variant") {
        try {
        res = await DalleGenerateVariant(variantBlobData, sliderValue, address);
        } catch (error) {
          toast({
            position: "top-right",
            title: "Error code: 0005",
            description: "Please see our 'Support' section in the FAQ and reach out with the error code.",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        }
      }
      // IF GENERATE MODE
      else {
        // RECORD EVENT IN AMPLIFY
        recordEvent(description, sliderValue);
        try {
        res = await openai.createImage({
          prompt: description,
          n: sliderValue,
          size: "1024x1024", // make 256 for test 
          response_format: "b64_json",
          user: address!.toLowerCase()
        });
        } catch (error) {
          toast({
            position: "top-right",
            title: "Error code: 0006",
            description: "Please see our 'Support' section in the FAQ and reach out with the error code.",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        }


        // console.log("createImage response:", res);
      }



      const newUrls = res.data.data
        .map((item: any) => item.b64_json)
        .filter((b64_json: any) => b64_json !== undefined) as string[];

      const { blobs, blobUrls } = await convertToBlobs(newUrls);

      setBlobData(blobs);
      setImageUrl(blobUrls);

    } catch (error: any) {
      if (error.response?.status === 400) {
        toast({
          position: "bottom",
          title: "Text may have flagged content",
          description: (
            <Box>
              <p>Pompt may have flagged content. Please avoid the following content categories:</p>
              <UnorderedList pl={4}>
                {ALL_MODERATION_CATEGORIES.map((category) => (
                  <ListItem key={category}>{category}</ListItem>
                ))}
              </UnorderedList>
            </Box>
          ),
          status: "error",
          duration: 30000,
          isClosable: true,//
        });
      }
    } finally {
      setLoading(false);
    }
  };


  const props = {
    setModel: setModel,
    sliderValue: sliderValue,
    model: model,
    prompts: prompts,
    imgData: blobData,
    imgURL: imageUrl,
    title: title,
    description: description,
    image: image,
    setOriginalImageUrl: setOriginalImageUrl,
    openEditor: () => setModalOpen(true),
    setVariantImageUrl: setVariantImageUrl,
    setParentMode: setParentMode,
  }

  /**
 * Callback function to update the title.
 *
 * @param {string} newTitle - New title for the image.
 */
  const updateTitle = (newTitle: string) => setTitle(newTitle);

  /**
 * Callback function to update the description.
 *
 * @param {string} newDescription - New description for the image.
 */
  const updateDescription = (newDescription: string) => setDescription(newDescription);

  let placeholderText;

  switch (parentMode) {
    case "default":
      placeholderText = `Describe your artwork request.\u000A\u000ATip: Add details like "impressionist" or "digit art"`;
      break;
    case "edit":
      placeholderText = `Describe your whole artwork (NOT only the erased section).`;
      break;
    case "variant":
      placeholderText = `When creating variants, your title and description will NOT impact the image, but will be used for the NFT metadata.`;
      break;
    default:
      placeholderText = `Describe your artwork request.\u000A\u000ATip: Add details like "impressionist" or "digit art"`;
  }

  const getButtonText = () => {
    if (!address) {
      return "Please connect your wallet";
    }
    if (model === "Stable Diffusion") {
      if (!title || !prompts.length) {
        return "Title & Description are required";
      }

    } else {
      if (!title || !description) {
        return "Title & Description are required";
      }
    }

    if (!canPayFee) {
      return `You need ${(calculateFeePerImage() * sliderValue).toFixed(3)} MATIC`;
    }
    return "Generate";
  }

  const addPrompt = () => {
    setPrompts([...prompts, { text: '', weight: 1 }]);
  }
  
  const setPromptText = (id: number, text: string) => {
    const newPrompts = [...prompts];
    newPrompts[id].text = text;
    setPrompts(newPrompts);
  }
  


  // https://github.com/chakra-ui/chakra-ui/issues/670
  // linear(to-tr, #000000,#020024, #090979, #00d4ff,#00d4ff)'
  return (
    <Box>
      <Box pt={10} display="flex" alignItems="center" justifyContent="center">
        <Box bg="#000000" width="100%" p={2} rounded={20} maxWidth="960px">
          <Flex flexDirection={['column-reverse', 'column-reverse', 'row']} alignItems="stretch">
            <Box flex="1" width="100%">
              <UserRec
                subImages={subImages}
                parentMode={parentMode}
                variantImageUrl={variantImageUrl}
                updateTitle={updateTitle}
                updateDescription={updateDescription}
                editedImageUrl={editedImageUrl}
                openEditor={() => setModalOpen(true)}
              />
            </Box>
            <Box flex="1" width="100%" bg="#000000" p={10} borderRadius="0 20px 20px 0">
              <Heading mb={6} color={'white'}>
                {parentMode === "edit" ? "Edit Image" : parentMode === "variant" ? "Generate Variants" : "Generate Image"}
              </Heading>
              <VStack >

                <Box
                  as="form"
                  maxWidth={['100%', '100%', '575px']}
                  width="100%"
                  px={0}
                >
                  <FormControl isRequired width="100%">
                    <FormLabel color="white">Select AI model</FormLabel>
                    <HStack mb={'2'}>
                      <Select color={'white'} width={model === 'Dalle' ? "100%" : "65%"} value={model} onChange={(e) => setModel(e.target.value as "Dalle" | "Stable Diffusion")}>
                        <option style={{ color: 'black' }}  value="Dalle">Dalle</option>
                        <option style={{ color: 'black' }}  value="Stable Diffusion">Stable Diffusion</option>
                      </Select>
                      {model === 'Stable Diffusion' && (
                        <Button onClick={handleAdvancedOptionsOpen} color='white' variant='outline' width="35%">Advanced Options</Button>
                      )}
                    </HStack>
                    <AdvancedOptions
                    addPrompt={addPrompt}
                    setPromptText={setPromptText}
                    prompts={prompts}
                    setPrompts={setPrompts}
                      PromptWeight={PromptWeight}
                      setPromptWeight={setPromptWeight}
                      ImageWeight={ImageWeight}
                      setImageWeight={setImageWeight}
                      parentMode={parentMode}
                      isOpen={isAdvancedOptionsOpen}
                      onClose={handleAdvancedOptionsClose}
                      description={description}
                      setDescription={setDescription}
                      title={title}
                      setTitle={setTitle}
                      dimension={dimension}
                      setDimension={setDimension}
                      cfgScale={cfgScale}
                      setCfgScale={setCfgScale}
                      clipGuidancePreset={clipGuidancePreset}
                      setClipGuidancePreset={setClipGuidancePreset}
                      sampler={sampler}
                      setSampler={setSampler}
                      seed={seed}
                      setSeed={setSeed}
                      steps={steps as Steps}
                      setSteps={setSteps}
                      stylePreset={stylePreset}
                      setStylePreset={setStylePreset}
                      setSliderValue={setSliderValue}
                      sliderValue={sliderValue}
                    />
                    <FormLabel color="white">Title</FormLabel>
                    <Input
                      name="title"
                      isRequired
                      value={title}
                      onChange={e => setTitle(e.target.value)}
                      mb={3}
                      placeholder="Give your future masterpiece a title."
                      color="white"
                    />
                    <FormLabel color="white">Description</FormLabel>
                    <Textarea
                      className="textarea-custom"
                      name="description"
                      mb={3}
                      placeholder={prompts.length > 1  && model === "Stable Diffusion" ? "Please click 'Advanced Options' to edit multi-prompt:\n" + prompts.map(prompt => prompt.text).join('\n') : placeholderText}
                      disabled={prompts.length > 1  && model === "Stable Diffusion"}
                      onChange={e => {
                        setDescription(e.target.value);
                        if (prompts.length === 1) {
                          setPromptText(0, e.target.value);
                        }
                      }}
                      color="white"
                      value={prompts.length > 1 && model === "Stable Diffusion" ? "Please click 'Advanced Options' to edit multi-prompt:\n" + prompts.map(prompt => prompt.text).join('\n') : description}
                    />
                    <FormLabel color="white">Number of Images</FormLabel>
                    <NumberInput
                      onChange={(valueString) => setSliderValue(Number(valueString))}
                      defaultValue={1}
                      max={10}
                      min={1}
                      color={'white'}
                      mb={3}
                      value={sliderValue}
                    >
                      <NumberInputField />
                      <NumberInputStepper>
                        <NumberIncrementStepper border='none' color={'white'} />
                        <NumberDecrementStepper border='none' color={'white'} />
                      </NumberInputStepper>
                    </NumberInput>
                  </FormControl>
                </Box>
                <Button
                  isLoading={loading}
                  isDisabled={!title || (model === "Dalle" ? !description : !prompts.length) || !address || !canPayFee || (sliderValue < 1 || sliderValue > 10)}
                  loadingText={status === "idle" ? 'Check Wallet...' : 'Generating Image...'}
                  onClick={payApiFee}
                  bg={"blue.300"}
                >
                  {getButtonText()}
                </Button>
                <DalleEdits image={image} setImage={setImage} originalImageUrl={originalImageUrl} setOriginalImageUrl={setOriginalImageUrl} parentMode={parentMode} editedImageUrl={editedImageUrl} variantImageUrl={variantImageUrl} setParentMode={setParentMode} setVariantImageUrl={setVariantImageUrl} setVariantBlobData={setVariantBlobData} setEditedImageUrl={setEditedImageUrl} setEditedBlobData={setEditedBlobData} open={isModalOpen} requestClose={closeModal} />
                {parentMode !== "default" ? <Button bg={"blue.300"} onClick={() => setParentMode("default")}>Exit Edit Mode</Button> : <></>}
                {props.imgURL.length > 0 ? <RequestModal {...props} /> : <></>}
              </VStack>
            </Box>
          </Flex>
        </Box>
      </Box>
      <FAQModal />
    </Box>





  )

}
