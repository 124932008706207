import React, { useState } from "react";
import { Skeleton, Grid, Image, Box, Text, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Button, useToast } from "@chakra-ui/react";
import { items } from './DataStructs/';
import { useNavigate } from "react-router-dom";

interface ShowcaseProps {
    images: { [key: string]: string };
    setDescription: (url: string) => void;
    setParentMode: (mode: "default" | "edit" | "variant") => void;
}

export const Showcase = ({setParentMode, images, setDescription }: ShowcaseProps) => {
    const navigate = useNavigate();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [currentItem, setCurrentItem] = useState(items[0]);
    const [loadedImages, setLoadedImages] = useState<{ [key: string]: boolean }>({});

    const handleImageLoad = (key: string) => {
        setLoadedImages(prevState => ({
            ...prevState,
            [key]: true,
        }));
    }


    const toast = useToast();

    const handleClick = (item: any) => {
        setCurrentItem(item);
        onOpen();
    }

        const handleUsePrompt = () => {
            const prompt = `${currentItem.description}`;
            setDescription(prompt);
            setParentMode("default");
            onClose();
            navigate('/');
            toast({
              title: "We have updated your Description, please add a title and click generate!",
              status: "success",
              duration: 9000,
              isClosable: true,
            });
            onClose();
    }

        return (
            <Box p={5}>
                <Grid templateColumns="repeat(auto-fit, minmax(250px, 1fr))" gap={6}>
                    {items.map((item, index) => (
                        <Box p={5} display="flex"
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="center"
                            key={index}>
                            <Skeleton startColor='blue.300' endColor='blue.900' isLoaded={loadedImages[item.key] || false}>
                                <Image border="2px solid white"
                                    boxShadow="md" src={images[item.key]} alt={item.title} boxSize="200px" objectFit="cover"
                                    onClick={() => handleClick(item)} _hover={{ cursor: "pointer", opacity: 0.8 }}
                                    onLoad={() => handleImageLoad(item.key)} // New line
                                    key={index} />
                            </Skeleton>
                        </Box>
                    ))}
                </Grid>

                <Modal isOpen={isOpen} onClose={onClose} >
                    <ModalOverlay />
                    <ModalContent display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                        <ModalHeader>{currentItem.title}</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                            <Image src={images[currentItem.key]} alt={currentItem.title} boxSize="250px" objectFit="cover" />
                            <Text textAlign="center">{currentItem.description}</Text>
                        </ModalBody>
                        <ModalFooter>
                            <Button colorScheme="blue" mr={3} onClick={handleUsePrompt}>
                                Use Prompt
                            </Button>


                            <Button variant="ghost" onClick={onClose}>Close</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </Box>
        );
    }
