export const categories = {
    "Art Style": ["digital art", "steampunk art", "cyberpunk art", "ukiyo-e art", "deco art", "vector art", "low poly art", "glitchcore art", "bauhaus art", "modern art", "line art", "vaporwave art", "3D art", "synthwave art", "pixel art", "watercolor art", "psychedelic art", "fractal pattern"],
    "Attributes": ["Detailed", "Award-Winning Art", "Trending on ArtStation", "Photorealistic", "Unreal Engine", "Fanart"],
    "Photorealistic": ["4K/8K", "15mm wide-angle lens", "35mm lens", "85mm lens", "200mm lens", "Bokeh", "Award-Winning", "Tilt-shift photography", "Cinematic Movie Photograph", "Macro", "polaroid", "kodak gold 200 film", "expired 35mm film"], // replace with real options
    "Lighting": ["Cinematic Lighting", "During Golden Hour", "Ambient Lighting", "Studio Lighting", "Lens Flare", "neon lighting"],
    "Artists": ["by Salvador Dali", "by Norman Rockwell", "by Victo Ngai", "by Banksy (Street Art)", "by Tim Burton", "by Da Vinci", "by Van Gogh", "by Monet", "art by Takashi Murakami"], // replace with real options
    "Experimental": ["Cybertronic", "Sticker illustration", "Tattoo design", "Tattoo of", "Cave painting", "WikiHow Guide", "IKEA Guide", "Movie Poster", "Linocut", "Origami", "made of yarn"],
    "Other": ["Nature Photography", "National Geographic", "Infrared Photography", "Ultraviolet Photography", "Pinhole Photography", "First person GoPro image", "Knolling"]
};

export const descriptions = { // complete this format for each category
    "Art Style": {
        "digital art": "This style represents artwork that is created using digital tools or techniques, often giving a sleek, modern look to the piece.",
        "steampunk art": "A subgenre of speculative fiction, steampunk art blends technology and aesthetics inspired by 19th-century industrial steam-powered machinery.",
        "cyberpunk art": "Influenced by a sub-genre of science fiction, cyberpunk art has a futuristic setting that tends to focus on a combination of low life and high tech.",
        "ukiyo-e art": "A traditional Japanese style of art that was popular from the 17th through 19th centuries, featuring depictions of beautiful women, kabuki actors, sumo wrestlers, scenes from history and folk tales, travel scenes and landscapes, flora and fauna, and erotica.",
        "deco art": "Also known as Art Deco, this style originated in the 1920s and influenced the design of buildings, furniture, jewelry, fashion, cars, movie theatres, trains, and everyday objects. It's often characterized by rich colors, bold geometric shapes, and lavish ornamentation.",
        "vector art": "A style of digital graphics that uses mathematical equations to create clean, crisp lines and shapes that can be scaled to any size without losing quality.",
        "low poly art": "A graphic design technique in which a limited number of polygons are used to represent 3D shapes. The resulting effect is a simplified, abstract aesthetic.",
        "glitchcore art": "Glitchcore art is a style that incorporates visual malfunctions or errors in digital media, usually characterized by vibrant, neon colors and pixelated or distorted elements.",
        "bauhaus art": "An art style influenced by the Bauhaus School in Germany during the 20th century, which promotes the combination of fine art, crafts, and technology. The aesthetic is characterized by its simplicity, functionality, and geometric shapes.",
        "modern art": "A broad genre of art produced between the late 19th century and the mid-20th century, characterized by a self-conscious break with traditional styles of art making.",
        "line art": "A style of drawing that uses straight or curved lines placed against a (usually plain) background, without gradations in shade or color to represent two-dimensional or three-dimensional objects.",
        "vaporwave art": "An aesthetic characterized by nostalgia for 1980s and 1990s computer graphics, early internet imagery, Greek statues, and tropical landscapes, often employing a saturated or pastel color palette.",
        "3D art": "This is a style of artwork that appears to have height, width, and depth. It can be created using a variety of mediums, including computer graphics, sculpture, and other forms of mixed media.",
        "synthwave art": "An art style reminiscent of the 1980s, reflecting the neon-colored, computerized aesthetic of that time, typically inspired by retrofuturism, video games, and cyberpunk.",
        "pixel art": "A style of digital art where images are created on the pixel level, most often associated with old computer and video game graphics.",
        "watercolor art": "A painting method in which the paints are made of pigments suspended in a water-based solution. Characterized by its transparency and the softness of the colors.",
        "psychedelic art": "Art style that tries to replicate or enhance the hallucinatory effects of psychedelic experiences, typically featuring highly saturated colors, intricate patterns, and surreal scenes.",
        "fractal pattern": "Patterns that are self-similar, meaning they are made up of smaller copies of the whole. Often seen in nature and now commonly recreated in digital art due to their mesmerizing and complex characteristics."
    },

    "Attributes": {
        "Detailed": "Incorporates intricate elements into the art which can provide richness, although may result in an overflow of minutiae.",
        "Award-Winning Art": "Inspired by highly original and creative art pieces that have garnered accolades, this modifier can enhance the uniqueness and quality of your output.",
        "Trending on ArtStation": "Uses additional training data derived from popular artwork on ArtStation, increasing the aesthetic appeal of your creations as they are inspired by community-approved visuals.",
        "Photorealistic": "Enhances the art's detail to achieve a life-like effect, while retaining artistic stylization. It is not meant to create a real-life photograph appearance.",
        "Unreal Engine": "Produces imagery akin to video-game visuals with smooth contours and superior clarity, offering a more refined detailing compared to the 'Photorealistic' modifier.",
        "Fanart": "Infuses a youthful, amateurish charm to the design, often incorporating elements like hearts and so forth."
    },

    "Photorealistic": {
        "4K/8K": "Images carrying these captions in the dataset typically signify high production value, thus using this modifier might result in more professionally shot visual output.",
        "15mm wide-angle lens": "Captures a wide scope of information within the image.",
        "35mm lens": "Ensures a balanced blur in the background with a standard zoom level.",
        "85mm lens": "Results in images that appear to be zoomed in with considerable background blur and detail emphasis on the subject.",
        "200mm lens": "Creates a highly zoomed in effect, with a significant background blur, making it look as though it's shot from a long distance and zoomed in significantly.",
        "Bokeh": "Intensifies the background blur with clear outer edges, which can be an alternative for the 'mm lens' prompts. This may also bring the subject closer to the camera.",
        "Award-Winning": "Increases the likelihood of generating high-quality and aesthetically pleasing images.",
        "Tilt-shift photography": "A photographic technique often used to create a miniature effect, manipulating the focus of the camera.",
        "Cinematic Movie Photograph": "Incorporates atmospheric and cinematic qualities to the image, including attractive color tones, composed image structure, and potential background blur and appealing camera angles.",
        "Macro": "Produces a close-up image with significant background blur, suitable for smaller objects or animals."
    },

    "Lighting": {
        "Cinematic Lighting": "Generates dramatic, movie-like imagery with high vibrancy and potentially pronounced sun rays.",
        "During Golden Hour": "The time frame just after sunrise or before sunset that offers a soft, warm natural light. It enhances the warmth of the created images.",
        "Ambient Lighting": "A soft, diffused light that is often used to create a natural, comfortable environment in a scene.",
        "Studio Lighting": "Creates an impression of professional studio light setup, highlighting the details of the primary subject against a contrasting background.",
        "Lens Flare": "Introduces a light streak into the image, giving an impression of a bright light source just outside the frame."
    },
    "Artists": {
        "by Salvador Dali": "Generates art with surrealistic elements and peculiar compositions, characteristic of Salvador Dali's style.",
        "by Norman Rockwell": "Produces images with a keen sense of narrative and detail that recall the works of Norman Rockwell.",
        "by Victo Ngai": "Creates vibrant, intricate designs reminiscent of the style of Victo Ngai.",
        "by Banksy (Street Art)": "Influences the output to feature Banksy's iconic, thought-provoking street art style.",
        "by Tim Burton": "Infuses the creation with Tim Burton's distinct, fantastical and somewhat eerie aesthetic.",
        "by Da Vinci": "Cultivates a classic, Renaissance feel inspired by Da Vinci's genius artistry.",
        "by Van Gogh": "Adopts Van Gogh's expressive, post-impressionistic style with its characteristic bold strokes and colors.",
        "by Monet": "Invokes Monet's style of impressionism, characterized by his emphasis on capturing light and natural forms.",
        "art by Takashi Murakami": "Generates images with the colorful, whimsical, and often psychedelic aesthetic of Takashi Murakami."
    },
    "Experimental": {
        "Cybertronic": "Adopts a futuristic aesthetic characterized by high-tech robotic and mechanical elements.",
        "Sticker illustration": "Produces designs suited for sticker format, typically simple, bold and eye-catching.",
        "Tattoo design": "Creates patterns and designs that would fit the aesthetics and conventions of tattoos.",
        "Tattoo of": "Generates an image of a particular object or concept rendered in a tattoo art style.",
        "Cave painting": "Emulates the ancient style of cave paintings, characterized by simple forms and figures.",
        "WikiHow Guide": "Imitates the simplistic and informative illustrations found in WikiHow guides.",
        "IKEA Guide": "Generates images that resemble the minimalist, instructional diagrams in IKEA assembly guides.",
        "Movie Poster": "Creates designs that suit the format and aesthetics of movie posters, combining visual appeal with informative elements.",
        "Linocut": "Adopts the style of linocut prints, characterized by strong lines and high contrast.",
        "Origami": "Produces visuals reminiscent of the art of origami, with its clean lines and geometric forms.",
        "made of yarn": "Generates images that give the impression of being crafted from yarn."
    },
    "Other": {
        "Nature Photography": "Produces images that highlight the beauty and intricacy of nature, possibly showcasing animals and natural phenomena in unique settings.",
        "National Geographic": "Creates visuals similar to those featured in National Geographic, often highlighting stunning views of nature and wildlife in their natural habitats.",
        "Infrared Photography": "Generates images that use the infrared spectrum of light, which can result in unusual and otherworldly color palettes.",
        "Ultraviolet Photography": "Creates images that capture the ultraviolet spectrum, often revealing details invisible to the naked eye.",
        "Pinhole Photography": "Adopts the aesthetic of pinhole photography, characterized by its dreamy atmosphere and wide field of view.",
        "First person GoPro image": "Produces images that mimic the first-person perspective commonly seen in GoPro footage, often capturing adventurous and active scenarios.",
        "Knolling": "Creates images using the knolling technique, where objects are arranged in parallel or at 90-degree angles, typically viewed from above."
    }

}; 

export interface Prompt {
    text: string;
    weight: number;
    originalText?: string;
    tags?: string[];
  }

