import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon, Link, UnorderedList, ListItem, Highlight, Box, Text, Heading, VStack, Tab, Tabs, TabList, TabPanels, TabPanel, Wrap, WrapItem, Button, Flex, Popover, PopoverTrigger, PopoverContent, PopoverHeader, PopoverBody, PopoverArrow, PopoverCloseButton
} from "@chakra-ui/react";
import { ExternalLinkIcon } from '@chakra-ui/icons'
import { descriptions } from './DataStructs';

type PopOverProps = {
  title: string;
  description: string;
};



// onClick={() => handleValueClick(title)}

const InfoPopover = ({ title, description }: PopOverProps) => (
  <Popover>
    <PopoverTrigger>
      <Button onClick={(e) => e.stopPropagation()}>
        {title}
      </Button>
    </PopoverTrigger>
    <PopoverContent>
      <PopoverArrow />
      <PopoverCloseButton />
      <PopoverHeader>{title}</PopoverHeader>
      <PopoverBody>
        <Text>{description}</Text>
      </PopoverBody>
    </PopoverContent>
  </Popover>
);

const ShortcomingsAccordion = () => (
  <>
    <Heading size='md' mb={2}>Dalle Shortcomings</Heading>
    <Accordion allowToggle>
      <AccordionItem>
        <AccordionButton _expanded={{ bg: 'blue.300', color: 'white' }}>
          <Box flex="1" textAlign="left">
            Generating Coherent Text
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel pb={4}>
          <UnorderedList>
            <ListItem>
              DALL·E 2 is capable of generating Latin letters, but it struggles with forming coherent, standalone words when tasked with generating specific textual content, such as signage or meme captions.
            </ListItem>
            <ListItem>
              In some cases, it can spell out common phrases or greetings accurately, but its performance is inconsistent across different words and contexts.
            </ListItem>
          </UnorderedList>
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionButton _expanded={{ bg: 'blue.300', color: 'white' }}>
          <Box flex="1" textAlign="left">
            Distinct Characteristics of Multiple Characters/Objects
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel pb={4}>
          <UnorderedList>
            <ListItem>
              When asked to generate scenes involving multiple characters or objects, DALL·E 2 often mixes up their characteristics. This can result in an entirely new persona or object, combining features from the originally specified entities.
            </ListItem>
            <ListItem>
              This issue also extends to color, shape, and other attributes of multiple objects in a scene.
            </ListItem>
          </UnorderedList>
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionButton _expanded={{ bg: 'blue.300', color: 'white' }}>
          <Box flex="1" textAlign="left">
            Challenging Conventional Beliefs
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel pb={4}>
          <UnorderedList>
            <ListItem>
              DALL·E 2 struggles to break away from conventional or natural scenarios. For example, when asked to generate a scene of a mouse chasing a cat, it tends to default to the expected scenario of a cat chasing a mouse, even when explicitly instructed otherwise.
            </ListItem>
            <ListItem>
              It also has difficulty creating unusual or unconventional character features, such as a Cyclops with one eye.
            </ListItem>
          </UnorderedList>
        </AccordionPanel>
      </AccordionItem>
      <AccordionItem>
        <AccordionButton _expanded={{ bg: 'blue.300', color: 'white' }}>
          <Box flex="1" textAlign="left">
            Inconsistent Human/Eye Representation
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel pb={4}>
          <UnorderedList>
            <ListItem>
              DALL·E 2's representation of eyes in generated characters can often appear peculiar or inconsistent.
            </ListItem>
            <ListItem>
              Generated eyes may not be looking in the same direction, have different-sized pupils, or other unusual features.
            </ListItem>
          </UnorderedList>
        </AccordionPanel>
      </AccordionItem>
      <AccordionItem>
        <AccordionButton _expanded={{ bg: 'blue.300', color: 'white' }}>
          <Box flex="1" textAlign="left">
            Twisty Puzzles Issues
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel pb={4}>
          <UnorderedList>
            <ListItem>
              While DALL·E 2 can generate images of a Rubik's cube, it struggles to depict it in a solved state, even when explicitly prompted to do so.
            </ListItem>
            <ListItem>
              The AI also has a tendency to generate impossible pieces in some of the images, such as Rubik's cube corners with two out of three sides being the same color, or center pieces for different colors adjacent to each other.
            </ListItem>
          </UnorderedList>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  </>
);

export const PromptGuide = () => {


  return (
    <Flex direction="column" alignItems="center" justifyContent="center" height="85vh" padding={4}>
      <VStack overflowY={'scroll'} align='stretch' w='80%' p={5} spacing={4} bgColor='white' borderRadius='lg'>

        <Heading size='lg' mb={4}>DALL·E 2 Prompt Guide</Heading>

        <Heading size='md' mb={2}>Introduction</Heading>
        <Text>
          A "Prompt" is the fundamental instruction that guides DALL·E 2 in creating the specific AI-generated art piece you desire. It's typically composed of a subject and one or more modifiers, using the format:
          <Text as={'b'}>
            <Highlight query={['[Subject]']}
              styles={{ px: '2', py: '1', rounded: 'full', bg: 'blue.100' }}>
              "A [Subject]
            </Highlight>
            <Highlight query={['[Modifier(s)]']}
              styles={{ px: '2', py: '1', rounded: 'full', bg: 'red.100' }}>
              [Modifier(s)]".
            </Highlight>
          </Text>
        </Text>

        <Text as={'b'} align={'center'}>
          <Highlight query={['An American high school student reading a newspaper']}
            styles={{ px: '2', py: '1', rounded: 'full', bg: 'blue.100' }}>
            “An American high school student reading a newspaper,
          </Highlight>
          <Highlight query={['in Chinese watercolor, award-winning painting']}
            styles={{ px: '2', py: '1', rounded: 'full', bg: 'red.100' }}>
            in Chinese watercolor, award-winning painting”
          </Highlight>
        </Text>

        <Heading size='md' mb={2}>Understanding Subjects and Modifiers</Heading>
        <Text>
          <Text>
            <Highlight query={['It could be an object, a person, an animal, a landscape, an abstract idea,']}
              styles={{ px: '2', py: '1', rounded: 'full', bg: 'blue.100' }}>
              The subject is the core concept or theme you want the AI to represent. It could be an object, a person, an animal, a landscape, an abstract idea, or any other thing you want the AI to create. It's important to choose a subject that the AI can recognize and generate, such as common objects or well-known themes.
            </Highlight>
          </Text>
        </Text>

        <Text>
          <Text>
            <Highlight query={["art style, specific attributes, degree of photorealism, lighting conditions"]}
              styles={{ px: '2', py: '1', rounded: 'full', bg: 'red.100' }}>
              The modifier, on the other hand, serves as a descriptor that provides additional context or style to the subject. It refines the output by specifying how the subject should be portrayed. Modifiers can involve aspects of art style, specific attributes, degree of photorealism, lighting conditions, resemblance to certain artists' styles, experimental elements, and more.
            </Highlight>
          </Text>
        </Text>

        <Heading size='md' mb={2}>Vetted Modifiers</Heading>
        <Text>
          Modifiers vetted by the Dalle community include:  <Text as={'b'}> Art Style, Attributes, Photorealistic, Lighting, Artists, Experimental, and Other.</Text> Each of these categories houses a range of options that can further specify your desired result.
        </Text>
        <Tabs variant="soft-rounded" colorScheme="blue">
          <TabList overflowX="auto" whiteSpace="nowrap">
            {Object.keys(descriptions).map(category => (
              <Tab _selected={{ color: 'black', bg: 'blue.300' }} key={category}>{category}</Tab>
            ))}
          </TabList>

          <TabPanels>
            {Object.entries(descriptions).map(([title, values], index) => (
              <TabPanel key={index}>
                <Wrap>
                  {Object.entries(values).map(([value, description]) => (
                    <WrapItem key={value}>
                      <InfoPopover title={value} description={description} />
                    </WrapItem>
                  ))}
                </Wrap>
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>

        <Heading size='md' mb={2}>Crafting an Effective Prompt</Heading>
        <Text>
          Crafting an effective prompt requires a strategic selection and arrangement of the subject and modifier. While the subject should be easily recognizable to the AI, the modifier should offer specific stylistic or contextual guidance. Note that effective prompts often emerge from a mix of creativity and experimentation, so don't be afraid to try different combinations of subjects and modifiers. For insights and ideas, consider joining our <Link href='https://discord.gg/SXuwZVs2' isExternal>
            Discord <ExternalLinkIcon mx='2px' />
          </Link> conversations regarding additional modifiers.
        </Text>

        <Heading size='md' mb={2}>Modifier Combinations</Heading>
        <Text>
          Remember, combinations of certain modifiers can be used to create unique and striking images! However, use caution to avoid combining modifiers in a way that doesn't make logical sense, such as mixing "Oil Painting" with "Photorealistic". To give you some inspiration, consider the following example of a great combination:
        </Text>
        <Text as={'b'} align={'center'}>

            "Glitchcore Vaporwave Digital Art of [Subject], Award Winning Art"
            </Text>
        <ShortcomingsAccordion />
        <Heading size='md' mb={2}>Additional Resources</Heading>
        <UnorderedList>
          <ListItem>
            <Link href='https://docs.google.com/document/d/11WlzjBT0xRpQhP9tFMtxzd0q6ANIdHPUBkMV-YB043U/edit#' isExternal>
              DALL·E 2 Prompt Engineering Guide <ExternalLinkIcon mx='2px' />
            </Link>
          </ListItem>
          <ListItem>
            <Link href='https://dallery.gallery/the-dalle-2-prompt-book/' isExternal>
              The DALL·E 2 Prompt Book (82 page e-Book) <ExternalLinkIcon mx='2px' />
            </Link>
          </ListItem>
          <ListItem>
            <Link href='https://openai.com/research/dall-e' isExternal>
              OpenAI DALL·E Research <ExternalLinkIcon mx='2px' />
            </Link>
          </ListItem>
        </UnorderedList>
        <Heading size='md' mb={2}>Conclusion</Heading>
        <Text>
          Remember, the key to mastering the use of DALL·E 2 prompts is practice and experimentation. Don't be afraid to explore different subjects and modifiers and to think outside the box in your prompt creation.
        </Text>

      </VStack>
    </Flex>
  );
};
