import { abi, testAbi } from "./abis";
export const TESTING_ENABLED = false;

export const hardhatConfig = {
  readOnlyChainId: 31337,
  readOnlyUrls: {
    31337: "http://127.0.0.1:8545/",
  },
  nftInterfaceAddress: "0xe7f1725E7734CE288F8367e1Bb143E90bb3F0512" as string,
  nftInterface: testAbi,
};

export const goerliConfig = {
  readOnlyChainId: 5,
  readOnlyUrls: {
    //https://goerli.infura.io/v3/61fd3f1e41834c5ebd42c6d193267d74
    5: "https://goerli.infura.io/v3/61fd3f1e41834c5ebd42c6d193267d74",
  },
  nftInterfaceAddress: "0xbffd0fbe3374eB25A25A8ceAf8AE8F231b46b89a" as string,
  nftInterface: abi
};

export const mumbaiConfig = {
  readOnlyChainId: 80001,
  readOnlyUrls: {
    80001: `https://polygon-mumbai.g.alchemy.com/v2/${process.env.REACT_APP_ALCHEMY_ID}`,
  },
  nftInterfaceAddress: process.env.REACT_APP_ADDRESS as string,
  nftInterface: abi
};


