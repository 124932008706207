export type Dimension = "512x512" | "512x768" | "512x1024" | "768x768" | "768x1024" | "1024x1024";
export type Steps = 15 | 30 | 50 | 100 | 150;
export type PricingTable = Record<Steps, Record<Dimension, number>>;

export const pricingTable: PricingTable = {
    15: {
      "512x512": 0.001,
      "512x768": 0.003,
      "512x1024": 0.004,
      "768x768": 0.005,
      "768x1024": 0.007,
      "1024x1024": 0.01,
    },
    30: {
      "512x512": 0.002,
      "512x768": 0.005,
      "512x1024": 0.008,
      "768x768": 0.01,
      "768x1024": 0.014,
      "1024x1024": 0.019,
    },
    50: {
      "512x512": 0.004,
      "512x768": 0.009,
      "512x1024": 0.013,
      "768x768": 0.016,
      "768x1024": 0.023,
      "1024x1024": 0.032,
    },
    100: {
      "512x512": 0.007,
      "512x768": 0.017,
      "512x1024": 0.026,
      "768x768": 0.031,
      "768x1024": 0.045,
      "1024x1024": 0.064,
    },
    150: {
      "512x512": 0.01,
      "512x768": 0.025,
      "512x1024": 0.039,
      "768x768": 0.046,
      "768x1024": 0.067,
      "1024x1024": 0.095,
    },
  };
  