import React, { useEffect, useState, useRef, useReducer } from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { HeaderNav } from './components/navigation/Sidebar';
import { ImageGeneration } from './components/body/ImageGeneration';
import { Showcase } from './components/body/Showcase';
import { PromptBuilder } from './components/body/PromptBuilder';
import { PromptGuide } from './components/body/PromptGuide';
import { Box, Flex, useToast, Image, Heading } from "@chakra-ui/react";
import Logo from "./images/ProvLogo.png";
import awsconfig from './aws-exports';
import { Amplify, Storage } from 'aws-amplify';
import { items } from './components/body/DataStructs';
import { FaTwitter, FaDiscord, FaGithub } from 'react-icons/fa';
import { Dimension, Steps, Prompt } from "./components/body/DataStructs/"
Amplify.configure(awsconfig);

const FeatherInitialState = {
  isLoading: true,
  opacity: 1,
  isHidden: false
};

const ACTIONS = {
  SET_LOADING: 'set_loading',
  SET_OPACITY: 'set_opacity',
  SET_HIDDEN: 'set_hidden',
} as const;

type State = {
  isLoading: boolean;
  opacity: number;
  isHidden: boolean;
};

type Action = 
  | { type: typeof ACTIONS.SET_LOADING; payload: boolean }
  | { type: typeof ACTIONS.SET_OPACITY; payload: number }
  | { type: typeof ACTIONS.SET_HIDDEN; payload: boolean };

function FeatherReducer(state: State, action: Action): State {
  switch (action.type) {
    case 'set_loading':
      return { ...state, isLoading: action.payload };
    case 'set_opacity':
      return { ...state, opacity: action.payload };
    case 'set_hidden':
      return { ...state, isHidden: action.payload };
    default:
      return state;
  }
}


// in src/App.tsx

export const App = () => {

  // State for the feather on the loading screen
  // const [isLoading, setIsLoading] = useState(true);
  // const [opacity, setOpacity] = useState(1);
  // const [isHidden, setIsHidden] = useState(false);
  const [FeatherState, FeatherDispatch] = useReducer(FeatherReducer, FeatherInitialState);
  const { isLoading, opacity, isHidden } = FeatherState;

  function setLoading(dispatch: React.Dispatch<Action>, payload: boolean) {
    dispatch({ type: ACTIONS.SET_LOADING, payload });
  }
  
  function setOpacity(dispatch: React.Dispatch<Action>, payload: number) {
    dispatch({ type: ACTIONS.SET_OPACITY, payload });
  }
  
  function setHidden(dispatch: React.Dispatch<Action>, payload: boolean) {
    dispatch({ type: ACTIONS.SET_HIDDEN, payload });
  }


  const isFirstRender = useRef(true); // Stop Toast on first visit to the page

  // State for the images in the UI pulled from S3
  const [images, setImages] = useState<{ [key: string]: string }>({});
  const [subImages, setSubImages] = useState<{ [key: string]: string }>({});


  // State for the DALLE image generation
  const [description, setDescription] = useState('')
  const [title, setTitle] = useState('')
  const [tags, setTags] = useState<string[]>([]);

  // State for controlling the UI 'mode' (default, edit, variant) and AI model (Dalle, Stable Diffusion) 
  const [parentMode, setParentMode] = useState<"default" | "edit" | "variant">("default");
  const [model, setModel] = useState<"Dalle" | "Stable Diffusion">("Dalle");

  // State for the image editing & variant generation
  const [originalImageUrl, setOriginalImageUrl] = useState<HTMLImageElement | null>(null);
  const [editedImageUrl, setEditedImageUrl] = useState<string>("");
  const [editedBlobData, setEditedBlobData] = useState<Blob | null>(null);
  const [variantImageUrl, setVariantImageUrl] = useState<string>("");
  const [variantBlobData, setVariantBlobData] = useState<Blob | null>(null);
  const [isModalOpen, setModalOpen] = useState(false);



  // State for the Stable Diffusion image generation
  const [dimension, setDimension] = useState<Dimension>("512x512");
  const [PromptWeight, setPromptWeight] = useState<number>(1);
  const [ImageWeight, setImageWeight] = useState<number>(0.35);
  const [cfgScale, setCfgScale] = useState<number>(7);
  const [clipGuidancePreset, setClipGuidancePreset] = useState<string>('NONE');
  const [sampler, setSampler] = useState<string>('NONE');
  const [seed, setSeed] = useState<number>(0);
  const [steps, setSteps] = useState<Steps>(15); // 50
  const [stylePreset, setStylePreset] = useState<string>('NONE');
  const [sliderValue, setSliderValue] = useState(1);
  const [prompts, setPrompts] = useState<Prompt[]>([{ text: ' ', weight: 1 }]);


  const closeModal = () => setModalOpen(false);
  const toast = useToast();

  const rotatingImageStyle = {
    position: 'fixed' as 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    animation: isLoading ? 'rotation 2s infinite linear' : '',
    opacity: opacity,
    zIndex: 9999,
    transition: 'opacity 3s',
    visibility: (isHidden ? 'hidden' : 'visible') as 'visible' | 'hidden',
  };

  const backgroundFadeStyle = {
    position: 'fixed' as 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'black',
    opacity: opacity,
    zIndex: 9998,
    transition: 'opacity 3s',
    visibility: (isHidden ? 'hidden' : 'visible') as 'visible' | 'hidden',
  };

  const projectNameStyle = {
    position: 'fixed' as 'fixed',
    top: '70%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: '#4299E1', // blue.300 color from Chakra UI
    fontSize: '2em',
    opacity: opacity,
    zIndex: 9999,
    transition: 'opacity 3s',
    visibility: (isHidden ? 'hidden' : 'visible') as 'visible' | 'hidden',
  };

  useEffect(() => {
    const fetchImages = async () => {
      const imagePromises = items.map(item => Storage.get(item.key, {
        level: "public",
        expires: 60 * 60 * 24 * 7, // 7 days

      }));
      const imageUrlsArray = await Promise.all(imagePromises);
      const imageUrls: { [key: string]: string } = {};
      items.forEach((item, index) => {
        imageUrls[item.key] = imageUrlsArray[index];
      });
      setImages(imageUrls);

      // Select a subset of images here, for example the first 3
      const subImageKeys = Object.keys(imageUrls).slice(0, 3);
      const subImages = subImageKeys.reduce((result, key) => {
        result[key] = imageUrls[key];
        return result;
      }, {} as { [key: string]: string });
      setSubImages(subImages);
    };

    fetchImages();
  }, []);


  // Inject the keyframes into the page's styles. You can place this code anywhere outside the component.
  const style = document.createElement('style');
  document.head.appendChild(style);
  style!.sheet!.insertRule(`
    @keyframes rotation {
      from {
        transform: translate(-50%, -50%) rotate(0deg);
      }
      to {
        transform: translate(-50%, -50%) rotate(360deg);
      }
    }
  `, style!.sheet!.cssRules.length);
  style!.sheet!.insertRule(`
    @keyframes fadeout {
      from {
        opacity: 1;
      }
      to {
        opacity: 0;
      }
    }
  `, style!.sheet!.cssRules.length);


  useEffect(() => {
    if (isLoading) {
      setOpacity(FeatherDispatch, 1);
    } else {
      setTimeout(() => setOpacity(FeatherDispatch, 0), 0);
    }
  }, [isLoading]);



  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(FeatherDispatch, false);
    }, 3000); // Display the rotating image for 5 seconds

    return () => clearTimeout(timer); // Clean up the timer
  }, []);

  useEffect(() => {
    if (isLoading) {
      setOpacity(FeatherDispatch, 1);
    } else {
      setTimeout(() => {
        setTimeout(() => setHidden(FeatherDispatch, true), 3000);  // Wait for transition to end
      }, 0);
    }
  }, [isLoading]);


  useEffect(() => {

    if (isFirstRender.current) {
      isFirstRender.current = false; // Update the flag after the first render
      return;
    }

    if (parentMode === "variant") {
      toast({
        title: "You're in Variant Mode",
        description: "Your title and description will NOT impact the image, but will be used for the NFT metadata",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } else if (parentMode === "edit") {
      toast({
        title: "You're in Edit Mode",
        description: `Tip: Don't worry about perfectly erasing the edges of the object. We'll take care of that for you.`,
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    }
  }
    //   } else if (parentMode === "default") {
    //     toast({
    //       title: "You're in Image Generation Mode",
    //       description: "Your title and description WILL entirely impact the image",
    //       status: "success",
    //       duration: 9000,
    //       isClosable: true,
    //     });

    //   }
    // } dummy commit
    , [parentMode]);

  const SocialIcons = () => (
    <Box display="flex" position="absolute" bottom={4} left={4}>
      <a href="https://twitter.com/provenance_ai" target="_blank" rel="noopener noreferrer">
        <FaTwitter size="2em" color="#1DA1F2" style={{ marginRight: '10px' }} />
      </a>
      <a href="https://discord.gg/F2Y6cPNa" target="_blank" rel="noopener noreferrer">
        <FaDiscord size="2em" color="#5865F2" style={{ marginRight: '10px' }} />
      </a>
      <a href="https://docs.prov.ai/" target="_blank" rel="noopener noreferrer">
        <FaGithub size="1.90em" color="#FFFFFF" />
      </a>
    </Box>
  );

  return (

    <Box bgGradient="linear(to-tr, #000000,#020024, #090979, #00d4ff,#00d4ff)" overflow="-moz-hidden-unscrollable" minHeight="100vh" >
      <Box style={backgroundFadeStyle} />
      <Image boxSize="200px" src={Logo} alt="Loading" style={rotatingImageStyle} />
      <Heading style={projectNameStyle} fontFamily={"fonts.heading"} as="h1" size="lg" letterSpacing={"tighter"} color={'blue.300'} mt={2} >
        &nbsp;Provenance
      </Heading>
      <Router>
        <Flex>
          <Box flex="1">
            <HeaderNav />
            <Routes>
              <Route path="/" element={<ImageGeneration
                prompts={prompts}
                setPrompts={setPrompts}
                model={model}
                setModel={setModel}
                parentMode={parentMode}
                setParentMode={setParentMode}
                editedImageUrl={editedImageUrl}
                setEditedImageUrl={setEditedImageUrl}
                editedBlobData={editedBlobData}
                setEditedBlobData={setEditedBlobData}
                variantImageUrl={variantImageUrl}
                setVariantImageUrl={setVariantImageUrl}
                variantBlobData={variantBlobData}
                setVariantBlobData={setVariantBlobData}
                title={title}
                setTitle={setTitle}
                description={description}
                setDescription={setDescription}
                isModalOpen={isModalOpen}
                setModalOpen={setModalOpen}
                closeModal={closeModal}
                originalImageUrl={originalImageUrl}
                setOriginalImageUrl={setOriginalImageUrl}
                images={images}
                setImages={setImages}
                subImages={subImages}
                // subImages={subImages}
                dimension={dimension}
                setDimension={setDimension}
                PromptWeight={PromptWeight}
                setPromptWeight={setPromptWeight}
                ImageWeight={ImageWeight}
                setImageWeight={setImageWeight}
                cfgScale={cfgScale}
                setCfgScale={setCfgScale}
                clipGuidancePreset={clipGuidancePreset}
                setClipGuidancePreset={setClipGuidancePreset}
                sampler={sampler}
                setSampler={setSampler}
                seed={seed}
                setSeed={setSeed}
                steps={steps}
                setSteps={setSteps}
                stylePreset={stylePreset}
                setStylePreset={setStylePreset}
                sliderValue={sliderValue}
                setSliderValue={setSliderValue}
              />
              } />
              <Route path="/prompt-builder" element={<PromptBuilder setModel={setModel} prompts={prompts} setPrompts={setPrompts} model={model} tags={tags} setTags={setTags} description={description} setDescription={setDescription} setParentMode={setParentMode} />} />
              <Route path="/showcase" element={<Showcase setParentMode={setParentMode} setDescription={setDescription} images={images} />} />
              <Route path="/prompt-guide" element={<PromptGuide />} />
            </Routes>
          </Box>
        </Flex>
      </Router>
      <SocialIcons />
    </Box>
  );
};

