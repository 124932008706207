// AdvancedOptions.js
import React, { useEffect, useState } from 'react';
import { FaRegQuestionCircle, FaPlus, FaMinus } from "react-icons/fa";
import {
    VStack,
    Text,
    Box,
    Select,
    HStack,
    Textarea,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Button,
    FormControl,
    FormLabel,
    Input,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
    IconButton,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverBody,
    PopoverArrow,
    PopoverCloseButton,
} from '@chakra-ui/react';
import { Steps, Dimension, Prompt } from "./DataStructs/";



interface FieldProps {
    id: number
    setDescription: (desc: string) => void;
    setPromptWeight: (height: number) => void;
    PromptWeight: number;
    prompts: Prompt[],
    setPrompts: (prompts: Prompt[]) => void;
    addPrompt: () => void;
    setPromptText: (id: number, text: string) => void;
    textColor: string;
}

export const PromptWeightCode = ({ textColor, addPrompt, setPromptText, id, setDescription, PromptWeight, setPromptWeight, prompts, setPrompts }: FieldProps) => {

    const removePrompt = (id: number) => {
        const newPrompts = prompts.filter((_, index) => index !== id);
        setPrompts(newPrompts);
    };

    return (
        <HStack width="100%" align={'end'}>
            <Box flex="8">
                {id === 0 && <FormLabel color={textColor}>Prompt</FormLabel>}
                <Input
                    className="textarea-custom"
                    name="description"
                    mb={3}
                    onChange={e => {
                        const newPrompts = [...prompts];
                        newPrompts[id].text = e.target.value;
                        setPrompts(newPrompts);
                        if (id === 0 && prompts.length === 1) {
                            setDescription(e.target.value);
                        }
                    }}
                    color={textColor}
                    value={prompts[id].text}
                />
            </Box>
            <Box flex="2">
                {id === 0 && <FormLabel color={textColor}>Weight</FormLabel>}
                <NumberInput
                    min={-10}
                    max={10}
                    step={0.1}
                    color={textColor}
                    name="PromptWeight"
                    isRequired
                    value={prompts[id].weight}
                    onChange={(valueString) => setPromptWeight(Number(valueString))}
                    mb={3}
                >
                    <NumberInputField />
                    <NumberInputStepper>
                        <NumberIncrementStepper color={textColor} />
                        <NumberDecrementStepper color={textColor} />
                    </NumberInputStepper>
                </NumberInput>
            </Box>
            <Box pb={'3'}>
                <IconButton
                    ml={'2'}
                    icon={<FaPlus />}
                    aria-label="add-prompt"
                    colorScheme="teal"
                    variant="outline"
                    onClick={() => setPrompts([...prompts, { text: '', weight: 1 }])}
                >
                    Add Prompt
                </IconButton>
                { prompts.length > 1 &&
                <IconButton
                    ml={'2'}
                    icon={<FaMinus />}
                    aria-label="remove-prompt"
                    colorScheme="red"
                    variant="outline"
                    onClick={() => removePrompt(id)}
                >
                    Remove Prompt
                </IconButton>
}
            </Box>
        </HStack>
    );
};


// your main component here...


interface AdvancedOptionsProps {
    isOpen: boolean;
    onClose: () => void;
    title: string;
    description: string;
    // height: number;
    // width: number;
    // setHeight: (height: number) => void;
    // setWidth: (width: number) => void;
    dimension: Dimension;
    setDimension: (dimensions: Dimension) => void;
    setTitle: (url: string) => void;
    setDescription: (desc: string) => void;
    cfgScale: number;
    setCfgScale: (cfgScale: number) => void;
    clipGuidancePreset: string;
    setClipGuidancePreset: (clipGuidancePreset: string) => void;
    sampler: string;
    setSampler: (sampler: string) => void;
    seed: number;
    setSeed: (seed: number) => void;
    steps: Steps;
    setSteps: (steps: Steps) => void;
    stylePreset: string;
    setStylePreset: (stylePreset: string) => void;
    setSliderValue: (sliderValue: number) => void;
    sliderValue: number;
    parentMode: "default" | "edit" | "variant";
    PromptWeight: number;
    ImageWeight: number;
    setPromptWeight: (height: number) => void;
    setImageWeight: (width: number) => void;
    prompts: Prompt[],
    setPrompts: (prompts: Prompt[]) => void;
    addPrompt: () => void;
    setPromptText: (id: number, text: string) => void;

    // options: {
    //   text_prompts: {
    //     text: string;
    //     weight: number;
    //   }[];
    // };
    // setOptions: Dispatch<SetStateAction<any>>;
}

const AdvancedOptions = ({
    addPrompt,
    setPromptText,
    prompts,
    setPrompts,
    dimension,
    setDimension,
    isOpen,
    onClose,
    title,
    description,
    setTitle,
    setDescription,
    // height,
    // width,
    // setHeight,
    // setWidth,
    cfgScale,
    setCfgScale,
    clipGuidancePreset,
    setClipGuidancePreset,
    sampler,
    setSampler,
    seed,
    setSeed,
    steps,
    setSteps,
    stylePreset,
    setStylePreset,
    setSliderValue,
    sliderValue,
    parentMode,
    PromptWeight,
    ImageWeight,
    setPromptWeight,
    setImageWeight
}: AdvancedOptionsProps) => {
    const [selectedField, setSelectedField] = useState<{ label: string, description: string } | null>(null);


    const clipGuidancePresets = ['NONE', 'FAST_BLUE', 'FAST_GREEN', 'SIMPLE', 'SLOW', 'SLOWER', 'SLOWEST'];
    const samplers = ['NONE', 'DDIM', 'DDPM', 'K_DPMPP_2M', 'K_DPMPP_2S_ANCESTRAL', 'K_DPM_2', 'K_DPM_2_ANCESTRAL', 'K_EULER', 'K_EULER_ANCESTRAL', 'K_HEUN', 'K_LMS'];
    const stylePresets = ['NONE', '3d-model', 'analog-film', 'anime', 'cinematic', 'comic-book', 'digital-art', 'enhance', 'fantasy-art', 'isometric', 'line-art', 'low-poly', 'modeling-compound', 'neon-punk', 'origami', 'photographic', 'pixel-art', 'tile-texture'];

    type FieldInfoType = {
        [key: string]: string;
        Steps: string;
        'Cfg Scale': string;
        Seed: string;
        'Clip Guidance Preset': string;
        Sampler: string;
    }

    const fieldInfo: FieldInfoType = {
        'Steps': 'This parameter denotes the number of iterations, or "steps," employed during the image transformation process in alignment with your chosen prompt. A greater number of steps could engender more intricate details in the output, yet may also prolong the processing time and increase cost.',
        'Cfg Scale': 'Ranging from 0 to 35, this parameter governs the adherence of the diffusion process to your designated prompt text. A higher value results in an image more reminiscent of your initial prompt, while a lower value infuses the final image with greater variability.',
        'Seed': "Choosing a value between 0 and 4294967295 sets the seed, initiating the random number generator used in the diffusion process. Employing a consistent seed guarantees replicable results. However, setting the seed to 0 or leaving it empty introduces a unique, random seed with each iteration.",
        'Clip Guidance Preset': 'This collection of predefined settings modifies the behavior of the diffusion process. Each preset encapsulates a unique balance between speed and image quality, thus impacting the visual outcome.',
        'Sampler': 'This denotes the specific algorithm responsible for executing the diffusion process. Various samplers may engender distinct aesthetic styles in the generated images. Leaving this field empty allows the system to autonomously select an apt sampler.',
        'Weight': 'Prompt Weight - A value between -10 & 10 (ideally between -2 & 2) designates the influence of text prompts in shaping the final image. A higher weight amplifies the impact of the text prompt, while a lower weight allows the initial image to hold more sway.',
        'Image Weight': 'Selecting a value between 0 and 1 designates the extent to which the initial image impacts the diffusion process. A higher weight yields an output image bearing a closer resemblance to the initial image, while a lower weight can lead to an output image diverging significantly from the initial image.',
    }



    interface FieldPopoverProps {
        label: keyof typeof fieldInfo;
    }

    const FieldPopover = ({ label }: FieldPopoverProps) => {
        const [selectedField, setSelectedField] = useState<{ label: string; description: string } | null>(null);

        const handleIconClick = () => {
            setSelectedField({ label: label as string, description: fieldInfo[label] });
        };

        return (
            <Popover>

                <HStack spacing={'-0.2'} cursor="pointer">
                    <Text>{label}</Text>
                    <PopoverTrigger>
                        <IconButton bg={'transparent'} size={'sm'} aria-label="Info" icon={<FaRegQuestionCircle />} onClick={() => handleIconClick()} />
                    </PopoverTrigger>
                </HStack>

                <PopoverContent>
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <PopoverHeader>{selectedField?.label}</PopoverHeader>
                    <PopoverBody>{selectedField?.description}</PopoverBody>
                </PopoverContent>
            </Popover>
        );
    };

    // React Component for the form field, Chakra HStack with 2 NumberInput feilds, 1 for Prompt weight and 1 for image weight
    const ImageDefault = () => {
        return (
            <VStack spacing={'-0.5'}>
                <FormLabel color="black">Dimensions</FormLabel>
                <Select
                    color={"black"}
                    name="dimensions"
                    isRequired
                    value={dimension}
                    onChange={(event) => {
                        setDimension(event.target.value as Dimension);
                    }}
                    mb={3}
                >
                    <option value="512x512">512x512</option>
                    <option value="512x768">512x768</option>
                    <option value="512x1024">512x1024</option>
                    <option value="768x768">768x768</option>
                    <option value="768x1024">768x1024</option>
                    <option value="1024x1024">1024x1024</option>
                </Select>
            </VStack>
        );
    };

    const VariantsWeights = () => {
        return (
            <FormControl>
                {/* <FormLabel color="black">Image Weight</FormLabel> */}
                <FieldPopover label="Image Weight" />
                <NumberInput
                    min={0}
                    max={1}
                    step={0.01}
                    color={"black"}
                    name="ImageWeight"
                    isRequired
                    value={ImageWeight}
                    onChange={(valueString) => setImageWeight(Number(valueString))}
                    mb={3}
                >
                    <NumberInputField />
                    <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                    </NumberInputStepper>
                </NumberInput>
            </FormControl>
        );
    };

    //     <PromptWeightCode
    //     setDescription={setDescription}
    //     PromptWeight={PromptWeight}
    //     setPromptWeight={setPromptWeight}
    // />

    return (
        <Box>
            <Modal size={'xl'} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Advanced Options</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormControl>
                            <FormLabel color="black">Title</FormLabel>
                            <Input
                                name="title"
                                isRequired
                                value={title}
                                onChange={e => setTitle(e.target.value)}
                                mb={3}
                                placeholder="Give your future masterpiece a title."
                                color="black"
                            />
                            {
                                prompts.map((prompt, index) => (
                                    <PromptWeightCode
                                        textColor={"black"}
                                        setPrompts={setPrompts}
                                        key={index}
                                        id={index}
                                        prompts={prompts}
                                        addPrompt={addPrompt}
                                        setPromptText={setPromptText}
                                        setDescription={setDescription}
                                        PromptWeight={prompt.weight}
                                        setPromptWeight={(weight) => {
                                            const newPrompts = [...prompts];
                                            newPrompts[index].weight = weight;
                                            setPrompts(newPrompts);
                                        }}
                                    />
                                ))
                            }



                            {parentMode === 'default' &&
                                <ImageDefault />
                            }

                            {parentMode === 'variant' &&
                                <VariantsWeights />
                            }

                            {/* Number of Images */}
                            <FormControl>
                                <FormLabel mt={'1'}>Number of Images</FormLabel>
                                <NumberInput
                                    min={1}
                                    max={10}
                                    value={sliderValue}
                                    onChange={(valueString) => setSliderValue(Number(valueString))}
                                >
                                    <NumberInputField />
                                    <NumberInputStepper>
                                        <NumberIncrementStepper />
                                        <NumberDecrementStepper />
                                    </NumberInputStepper>
                                </NumberInput>
                            </FormControl>

                            {/* Steps */}

                            <FormControl>
                                <FormLabel>
                                    <FieldPopover label="Steps" />
                                </FormLabel>
                                <Select
                                    value={steps}
                                    onChange={(event) => setSteps(Number(event.target.value) as Steps)}
                                >
                                    <option value="15">15</option>
                                    <option value="30">30</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                    <option value="150">150</option>
                                </Select>
                            </FormControl>

                            <FormControl>
                                {/* Cfg Scale */}
                                <FormLabel>

                                    <FieldPopover label="Cfg Scale" />
                                </FormLabel>
                                <NumberInput min={0} max={35} value={cfgScale} onChange={(valueString) => setCfgScale(Number(valueString))}>
                                    <NumberInputField />
                                    <NumberInputStepper>
                                        <NumberIncrementStepper />
                                        <NumberDecrementStepper />
                                    </NumberInputStepper>
                                </NumberInput>
                            </FormControl>
                        </FormControl>

                        {/* Seed */}

                        <FormControl>
                            <FormLabel>
                                <FieldPopover label="Seed" />
                            </FormLabel>
                            <NumberInput
                                min={0}
                                max={4294967295}
                                value={seed}
                                onChange={(valueString) => setSeed(Number(valueString))}
                            >
                                <NumberInputField />
                                <NumberInputStepper>
                                    <NumberIncrementStepper />
                                    <NumberDecrementStepper />
                                </NumberInputStepper>
                            </NumberInput>
                        </FormControl>
                        {/* Clip Guidance Preset */}
                        <FormControl>
                            <FormLabel>
                                <FieldPopover label="Clip Guidance Preset" />
                            </FormLabel>
                            <Select value={clipGuidancePreset} onChange={(e) => setClipGuidancePreset(e.target.value)}>
                                {clipGuidancePresets.map((preset) => (
                                    <option key={preset} value={preset}>
                                        {preset}
                                    </option>
                                ))}
                            </Select>
                        </FormControl>
                        {/* Sampler */}
                        <FormControl>
                            <FormLabel>
                                <FieldPopover label="Sampler" />
                            </FormLabel>
                            <Select value={sampler} onChange={(e) => setSampler(e.target.value)}>
                                {samplers.map((sample) => {
                                    if (clipGuidancePreset !== 'NONE' && (sample.includes('_ANCESTRAL') || sample.includes('NONE'))) {
                                        return (
                                            <option key={sample} value={sample}>
                                                {sample}
                                            </option>
                                        );
                                    } else if (clipGuidancePreset === 'NONE') {
                                        return (
                                            <option key={sample} value={sample}>
                                                {sample}
                                            </option>
                                        );
                                    }
                                })}
                            </Select>
                        </FormControl>

                        {/* Style Preset */}
                        <FormControl>
                            <FormLabel>Style Preset</FormLabel>
                            <Select value={stylePreset} onChange={(e) => setStylePreset(e.target.value)}>
                                {stylePresets.map((preset) => (
                                    <option key={preset} value={preset}>
                                        {preset}
                                    </option>
                                ))}
                            </Select>
                        </FormControl>

                        {/* Implement similar form controls for the other fields */}
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={onClose}>
                            Save
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <Modal isOpen={!!selectedField} onClose={() => setSelectedField(null)}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{selectedField?.label}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {selectedField?.description}
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={() => setSelectedField(null)}>
                            Close
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    );
};

export default AdvancedOptions;
