import { Link, useLocation } from 'react-router-dom';
import { Box, Flex, Image, Heading, IconButton, Drawer, DrawerOverlay, DrawerContent, useDisclosure, useMediaQuery } from "@chakra-ui/react";
import { FaTwitter, FaDiscord, FaGithub, FaBars } from 'react-icons/fa';
import { Header } from "../header/Header"

export const HeaderNav = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  const location = useLocation();

  const SocialIcons = () => (
    <Box display="flex">
      <a href="https://twitter.com/provenance_ai" target="_blank" rel="noopener noreferrer">
        <FaTwitter size="2em" color="#1DA1F2" style={{ marginRight: '10px' }} />
      </a>
      <a href="https://discord.gg/F2Y6cPNa" target="_blank" rel="noopener noreferrer">
        <FaDiscord size="2em" color="#5865F2" style={{ marginRight: '10px' }} />
      </a>
      <a href="https://docs.prov.ai/" target="_blank" rel="noopener noreferrer">
        <FaGithub size="1.90em" color="#FFFFFF" />
      </a>
    </Box>
  );

  const NavContent = () => (
    <Flex direction={isLargerThan768 ? "row":"column"} h="100%">
      <Link to="/" onClick={onClose}>
        <Box
          display="block"
          color={location.pathname === '/' ? 'white' : 'gray.300'}
          px={4}
          py={2}
          ml={isLargerThan768 ? 4:0}
          borderRadius="md"

        // bg={location.pathname === '/' ? 'blue.500' : 'transparent'}
        >
          Image Generation
        </Box>
      </Link>
      <Link to="/prompt-builder" onClick={onClose}>
        <Box
          display="block"
          color={location.pathname === '/prompt-builder' ? 'white' : 'gray.300'}
          px={4}
          py={2}
          borderRadius="md"
        // bg={location.pathname === '/showcase' ? 'blue.500' : 'transparent'}
        >
          Prompt Builder
        </Box>
      </Link>
      <Link to="/showcase" onClick={onClose}>
        <Box
          display="block"
          color={location.pathname === '/showcase' ? 'white' : 'gray.300'}
          px={4}
          py={2}
          borderRadius="md"
        // bg={location.pathname === '/showcase' ? 'blue.500' : 'transparent'}
        >
          Showcase
        </Box>
      </Link>
      <Link to="/prompt-guide" onClick={onClose}>
        <Box
          display="block"
          color={location.pathname === '/prompt-guide' ? 'white' : 'gray.300'}
          px={4}
          py={2}
          borderRadius="md"
        // bg={location.pathname === '/showcase' ? 'blue.500' : 'transparent'}
        >
          Prompt Guide
        </Box>
      </Link>
      {isLargerThan768 ? <></> : <Flex ml={3} mb={3} mt="auto"><SocialIcons/></Flex>}
    </Flex>
  );

  return (
    <Box>
      <Flex align="flex-start" justifyContent="space-between" mt={3}>
        <Flex align="flex-start">
          {
          isLargerThan768 ?
            <>
              <Image src={require("../header/ProvLogo.png")} alt="Provenance Logo" boxSize="40px" />
              <Link to="/" >
              <Heading
                fontFamily={"fonts.heading"}
                as="h1"
                size="lg"
                letterSpacing={"tighter"}
                color={'blue.200'}
                ml={2}
              >
                Provenance
              </Heading> </Link> </> : <></>

            
}
          {isLargerThan768 ? <NavContent /> : <IconButton position="absolute" top={2} right={2} aria-label="Open Menu" icon={<FaBars />} onClick={onOpen} />}

        </Flex>
        <Header />
      </Flex>
      {!isLargerThan768 && (
        <Drawer isOpen={isOpen} placement="left" onClose={onClose} size={'xs'}>
          <DrawerOverlay>
            <DrawerContent bg="#000000">
              <NavContent />
            </DrawerContent>
          </DrawerOverlay>
        </Drawer>
      )}
    </Box>
  );

};
