import { ColorModeScript } from "@chakra-ui/react"
import * as React from "react"
import * as ReactDOM from "react-dom/client"
import { App } from "./App"
import reportWebVitals from "./reportWebVitals"
import * as serviceWorker from "./serviceWorker"
import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import { DAppProvider } from "@usedapp/core";
import { TESTING_ENABLED, hardhatConfig, goerliConfig } from "./config";
//Rainbow Wallet Config
import '@rainbow-me/rainbowkit/styles.css';
import {
  getDefaultWallets,
  RainbowKitProvider,
} from '@rainbow-me/rainbowkit';
import { configureChains, createClient, WagmiConfig } from 'wagmi';
import { polygon, polygonMumbai, goerli } from 'wagmi/chains';
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { publicProvider } from 'wagmi/providers/public';
import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';
Amplify.configure(awsconfig);


const isProd = process.env.REACT_APP_IS_PROD === 'true';

type ChainConfiguration = typeof polygon | typeof goerli | typeof polygonMumbai;

const WalletChains: ChainConfiguration[] = isProd ? [polygon] : [goerli, polygonMumbai];



//const apiKey = process.env.REACT_APP_INFURA_API_KEY;

const { chains, provider } = configureChains(
  WalletChains,
  [
    alchemyProvider({ apiKey: process.env.REACT_APP_ALCHEMY_ID! }),
    publicProvider()
  ]
);

const { connectors } = getDefaultWallets({
  appName: 'My RainbowKit App',
  chains
});

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider
})


  



// Need to Change this API key, but it's not working for in ENV some reason


const colors = {
  brand: {
    900: '#1a365d',
    800: '#153e75',
    700: '#2a69ac',
  },
}

const config = TESTING_ENABLED ? hardhatConfig : goerliConfig;

const theme = extendTheme({ colors })


const container = document.getElementById("root")
if (!container) throw new Error('Failed to find the root element');
const root = ReactDOM.createRoot(container)

root.render(
  <React.StrictMode>
    <ColorModeScript />
    <ChakraProvider theme={theme}>
      <WagmiConfig client={wagmiClient}>
        <RainbowKitProvider chains={chains}>
          <DAppProvider config={config}>
            <App />
          </DAppProvider>
        </RainbowKitProvider>
      </WagmiConfig>
    </ChakraProvider>
  </React.StrictMode>,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

