// create function to view title, description, & sliderValue in Amplify Analytics record
import { Analytics } from "aws-amplify";
Analytics.enable();


export const recordEvent = (description: string, sliderValue: number) => {
    if (description !== "") {
        console.log("description: ", description, "sliderValue: ", sliderValue);
        try {
            Analytics.record({
                name: "Dalle Generation",
                immediate: true,
                attributes: {
                    description: description,
                    // sliderValue: sliderValue.toString()
                },
                metrics: { sliderValue: sliderValue }
            });
            // Analytics.record({
            //   name: 'albumVisit',
            //   attributes: {},
            //   metrics: { minutesListened: 30 }
            // });
        } catch (error) {
            console.log("Analytics Error: ", error);
        }
    }
}