import { FormLabel, Select, useToast, Divider, Text, Heading, Box, Tab, Tabs, TabList, TabPanel, TabPanels, Wrap, WrapItem, Button, HStack, CloseButton, Textarea, Flex, VStack } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { categories } from "./DataStructs/";
import { PromptWeightCode } from "./AdvancedOptions"
import { Prompt } from "./DataStructs/";

interface PromptBuilderProps {
    setModel: (model: "Dalle" | "Stable Diffusion") => void;
    description: string;
    setDescription: (desc: string) => void;
    tags: string[];
    setTags: (tags: string[]) => void;
    setParentMode: (mode: "default" | "edit" | "variant") => void;
    model: "Dalle" | "Stable Diffusion";
    prompts: Prompt[],
    setPrompts: (prompts: Prompt[]) => void;
}

/**
 * `PromptBuilder` component allows the user to construct prompts and assign
 * tags to them. It behaves differently based on the chosen model (either
 * 'Dalle' or 'Stable Diffusion').
 *
 * @component
 * @param {Object} props - The properties object.
 * @param {'Dalle'|'Stable Diffusion'} props.model - The chosen model.
 * @param {Prompt[]} props.prompts - Array of existing prompts.
 * @param {function} props.setPrompts - Function to update prompts.
 * @param {string} props.description - Base prompt description.
 * @param {function} props.setDescription - Function to update base prompt description.
 * @param {string[]} props.tags - Array of existing tags.
 * @param {function} props.setTags - Function to update tags.
 * @param {function} props.setParentMode - Function to update the parent mode.
 *
 * @returns {JSX.Element} PromptBuilder component.
 */
export const PromptBuilder = ({ setModel, model, prompts, setPrompts, description, setDescription, tags, setTags, setParentMode }: PromptBuilderProps) => {
    const navigate = useNavigate();
    const toast = useToast();

    /**
 * handleTagClick method handles the tag selection for both models.
 * For the 'Dalle' model, it manages tags in the global tags array.
 * For the 'Stable Diffusion' model, it manages tags in the individual prompts.
 *
 * @param {number} promptIndex - The index of the prompt being updated.
 * @param {string} tag - The tag to add or remove.
 */
    const handleTagClick = (promptIndex: number, tag: string) => {
        if (model === "Dalle") {
            if (tags.includes(tag)) {
                setTags(tags.filter(existingTag => existingTag !== tag));
            } else {
                setTags([...tags, tag]);
            }

        } else {
            const newPrompts = [...prompts];
            if (newPrompts[promptIndex].tags?.includes(tag)) {
                newPrompts[promptIndex].tags = newPrompts[promptIndex].tags?.filter(existingTag => existingTag !== tag);
            } else {
                if (newPrompts[promptIndex].tags) {
                    newPrompts[promptIndex].tags!.push(tag);
                } else {
                    newPrompts[promptIndex].tags = [tag];
                }
            }
            setPrompts(newPrompts);
        }
    };

    /**
 * handleTagRemove method handles the removal of tags for both models.
 * For the 'Dalle' model, it updates the global tags array.
 * For the 'Stable Diffusion' model, it updates the tags in individual prompts.
 *
 * @param {number} promptIndex - The index of the prompt being updated.
 * @param {string} tagToRemove - The tag to remove.
 */
    const handleTagRemove = (promptIndex: number, tagToRemove: string) => {
        if (model === "Dalle") {
            setTags(tags.filter(tag => tag !== tagToRemove));
        }
        else {
            const newPrompts = [...prompts];
            if (newPrompts[promptIndex].tags) {
                newPrompts[promptIndex].tags = newPrompts[promptIndex].tags!.filter(tag => tag !== tagToRemove);
                setPrompts(newPrompts);
            }
        }
    };

    /**
     * handleDoneClick method finalizes the prompt and its associated tags.
     * For the 'Dalle' model, it appends tags to the base description.
     * For the 'Stable Diffusion' model, it appends tags to each prompt.
     */

    const handleDoneClick = () => {
        let finalPrompt = description.trim();
        if (!finalPrompt.endsWith('.')) {
            finalPrompt += '.';
        }

        // If model is Stable Diffusion, append tags to corresponding prompts
        if (model === "Stable Diffusion") {
            const newPrompts = prompts.map((prompt) => {
                // Append tags to the prompt
                if (prompt.tags && prompt.tags.length > 0) {
                    let newPrompt = { ...prompt };
                    newPrompt.originalText = newPrompt.text; // Store the original text
                    newPrompt.text += " " + newPrompt.tags!.join(", ");

                    // Clear tags
                    newPrompt.tags = [];

                    return newPrompt;
                }
                return prompt;
            });
            setPrompts(newPrompts);
        } else {
            finalPrompt += ' ' + tags.join(", ");
            setDescription(finalPrompt);

            // Clear tags
            setTags([]);

        }

        // setParentMode("default");
        navigate('/');
        toast({
            title: "We have updated your Description, please add a title and click generate!",
            status: "success",
            duration: 9000,
            isClosable: true,
        });
    };

    /**
     * handleClearClick method clears tags based on the chosen model.
     * For the 'Dalle' model, it clears all tags from the base description.
     * For the 'Stable Diffusion' model, it clears all tags from the prompts.
     */

    const handleClearClick = () => {
        if (model === "Dalle") {
            const index = description.lastIndexOf(".");
            if (index !== -1) {
                setDescription(description.slice(0, index + 2));
            }
            setTags([]);
        } else if (model === "Stable Diffusion") {
            const newPrompts = prompts.map(prompt => {
                if (prompt.tags) {
                    prompt.tags = [];
                }
                // If we have stored the original text, restore it
                if (prompt.originalText) {
                    prompt.text = prompt.originalText;
                    delete prompt.originalText; // We don't need the original text anymore
                }
                return prompt;
            });
            setPrompts(newPrompts);
        }
    };

    /**
     * addPrompt method adds a new prompt to the list of prompts.
     */

    const addPrompt = () => {
        setPrompts([...prompts, { text: '', weight: 1, tags: [] }]);
    };

    /**
 * setPromptText method updates the text of a prompt.
 *
 * @param {number} id - The index of the prompt being updated.
 * @param {string} text - The new text for the prompt.
 */

    const setPromptText = (id: number, text: string) => {
        const newPrompts = [...prompts];
        newPrompts[id].text = text;
        setPrompts(newPrompts);
    }


    return (
        <Flex pt={'10px'} direction="column" alignItems="center" justifyContent="center" height="90vh" padding={4}>
            <Box overflowY={'scroll'} bg={'black'} width="full" height={'700px'} maxWidth="600px" p={'20px'} rounded={'10'}>
                <Heading color="white" mb={2}>Prompt Builder</Heading>
                <Box position="relative">
                <FormLabel color="white">Select AI model</FormLabel>
                    <HStack mb={'2'}>
                      <Select color={'white'} width={model === 'Dalle' ? "100%" : "65%"} value={model} onChange={(e) => setModel(e.target.value as "Dalle" | "Stable Diffusion")}>
                        <option style={{ color: 'black' }}  value="Dalle">Dalle</option>
                        <option style={{ color: 'black' }}  value="Stable Diffusion">Stable Diffusion</option>
                      </Select>
                    </HStack>
                    <VStack spacing={2} alignItems="stretch" >
                        {model === "Dalle" &&
                            <Wrap p={2}>
                                {tags.map((tag, index) => (
                                    <WrapItem key={tag}>
                                        <Box bg="blue.300" borderRadius="md" color="white">
                                            <HStack spacing={1}>
                                                <Text p={'3px'} size="xs">{tag}</Text>
                                                <CloseButton size="md" onClick={() => handleTagRemove(index, tag)} />
                                            </HStack>
                                        </Box>
                                    </WrapItem>
                                ))}
                            </Wrap>
                        }

                        {model === "Dalle" ? (
                            <Textarea
                                bg="black"
                                color="white"
                                value={description}
                                placeholder="Base Prompt..."
                                onChange={(e) => setDescription(e.target.value)}
                                minHeight="100px"
                            />
                        ) : (
                            prompts.map((prompt, index) => (
                                <VStack key={index} spacing={2} alignItems="stretch">
                                    <Wrap p={2}>
                                        {prompt.tags && prompt.tags.map(tag => (
                                            <WrapItem key={tag}>
                                                <Box bg="blue.300" borderRadius="md" color="white">
                                                    <HStack spacing={1}>
                                                        <Text p={'3px'} size="xs">{tag}</Text>
                                                        <CloseButton size="md" onClick={() => handleTagRemove(index, tag)} />
                                                    </HStack>
                                                </Box>
                                            </WrapItem>
                                        ))}
                                    </Wrap>
                                    <PromptWeightCode
                                        textColor={"white"}
                                        setPrompts={setPrompts}
                                        key={index}
                                        id={index}
                                        prompts={prompts}
                                        addPrompt={addPrompt}
                                        setPromptText={setPromptText}
                                        setDescription={setDescription}
                                        PromptWeight={prompt.weight}
                                        setPromptWeight={(weight) => {
                                            const newPrompts = [...prompts];
                                            newPrompts[index].weight = weight;
                                            setPrompts(newPrompts);
                                        }}
                                    />
                                </VStack>
                            ))
                        )}
                        <HStack width="100%" spacing={2}>
                            <Button bg="blue.300" onClick={handleDoneClick} width={tags.length > 0 ? "75%" : "100%"}>Done</Button>
                            {((model === "Dalle" ? tags : prompts[prompts.length - 1]?.tags) ?? []).length > 0 && (
                                <Button bg="red.300" onClick={handleClearClick} width="25%">
                                    Clear Tags
                                </Button>
                            )}
                        </HStack>
                    </VStack>
                </Box>
                <Flex width={'100%'} align="center" pt={'2'}>
                    <Divider />
                    <Text as={'b'} color={'white'} padding="2">Modifiers</Text>
                    <Divider />
                </Flex>

                <Tabs mt={4} variant="soft-rounded" colorScheme="blue">
                    <TabList whiteSpace="nowrap">
                        <Wrap>
                            {Object.keys(categories).map(category => (
                                <Tab _selected={{ color: 'white', bg: 'blue.300' }} key={category}>{category}</Tab>
                            ))}
                        </Wrap>
                    </TabList>
                    <Flex width={'100%'} align="center">
                        <Divider />
                        <HStack as={'b'} color={'white'} padding="2"><Text>Value</Text></HStack>
                        <Divider />
                    </Flex>
                    <TabPanels>
                        {Object.values(categories).map((category, index) => (
                            <TabPanel key={index}>
                                <Wrap>
                                    {category.map(tag => (
                                        <WrapItem key={tag}>
                                            <Button
                                                colorScheme={
                                                    model === "Dalle"
                                                        ? tags.includes(tag) ? "blue" : "gray"
                                                        : prompts[prompts.length - 1].tags?.includes(tag) ? "blue" : "gray"
                                                }
                                                onClick={() => handleTagClick(prompts.length - 1, tag)}
                                            >
                                                {tag}
                                            </Button>
                                        </WrapItem>
                                    ))}
                                </Wrap>
                            </TabPanel>
                        ))}
                    </TabPanels>
                </Tabs>
            </Box>
        </Flex>
    );
};
