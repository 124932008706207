export const convertToBlobs = async (b64_data_list: string[]): Promise<{ blobs: Blob[], blobUrls: string[] }> => {
    const blobs: Blob[] = [];
    const blobUrls: string[] = [];

    for (const b64_data of b64_data_list) {
      const decodedData = atob(b64_data);
      const bytes = new Uint8Array(decodedData.length);
      for (let i = 0; i < decodedData.length; i++) {
        bytes[i] = decodedData.charCodeAt(i);
      }
      const blob = new Blob([bytes], { type: "image/png" });
      blobs.push(blob);
      const blobUrl = URL.createObjectURL(blob);
      blobUrls.push(blobUrl);
    }
    return { blobs, blobUrls };
  };

  // Helper function to check if a number is a multiple of 64
  const isMultipleOf64 = (num: number) => num % 64 === 0;

  // Check if the height and width of an image are a multiple of 64
  export const checkImgDimension = (file: File): Promise<boolean> => {
    return new Promise((resolve) => {
      const img = new Image();
      const reader = new FileReader();

      reader.onload = (e) => {
        if (e.target) {
          img.src = e.target.result as string;

          img.onload = () => {
            resolve(isMultipleOf64(img.naturalWidth) && isMultipleOf64(img.naturalHeight));
          }
        }
      };

      reader.readAsDataURL(file);
    });
  };

  export const cropImageBehindScenes = (file: File): Promise<Blob> => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        const reader = new FileReader();

        reader.onload = (e) => {
            if (e.target) {
                img.src = e.target.result as string;

                img.onload = () => {
                    const imageWidth = img.naturalWidth;
                    const imageHeight = img.naturalHeight;
                    console.log(`Original dimensions: ${imageWidth}x${imageHeight}`);

                    const canvas = document.createElement('canvas');
                    const cropWidth = Math.floor(imageWidth / 64) * 64;
                    const cropHeight = Math.floor(imageHeight / 64) * 64;
                    console.log(`Cropped dimensions: ${cropWidth}x${cropHeight}`);

                    canvas.width = cropWidth;
                    canvas.height = cropHeight;
                    const ctx = canvas.getContext('2d')!;
                    ctx.drawImage(
                        img,
                        (imageWidth - cropWidth) / 2,
                        (imageHeight - cropHeight) / 2,
                        cropWidth,
                        cropHeight,
                        0,
                        0,
                        cropWidth,
                        cropHeight
                    );

                    canvas.toBlob((blob) => {
                        if (blob) {
                            resolve(blob);
                            // toast({
                            //   title: "Image dimensions adjusted",
                            //   description: `Your image's dimensions have been modified from ${imageWidth}x${imageHeight} to ${cropWidth}x${cropHeight} to ensure compatibility with the Stable Diffusion algorithm, which requires dimensions to be divisible by 64.`,
                            //   status: "info",
                            //   duration: 9000,
                            //   isClosable: true,//
                            // });
                        } else {
                            reject(new Error("Failed to create blob from canvas"));
                        }
                    }, 'image/png');
                }
            }
        };

        reader.readAsDataURL(file);
    });
};