// in src/components/Header.tsx
import React from "react";
import { Image, Heading, Flex, useBreakpointValue, useMediaQuery, useDisclosure } from "@chakra-ui/react";
import WalletModal from "./WalletConnection/WalletModal";
import { ConnectButton } from '@rainbow-me/rainbowkit';

export const Header = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  const textColor = useBreakpointValue({ base: "#000000", md: "#00d4ff" });

  return (
    <Flex
      as="nav"
      align="center"
      justify="space-between"
      wrap="wrap"
      pr={4}
      pt={2}
      color="white"
      justifyContent={{ base: "center", md: "flex-end" }}
    >
      {isLargerThan768 ? null : (
        <Flex align="center" mr={5}>
          <Image src={require("./ProvLogo.png")} alt="Provenance Logo" boxSize="50px" />
          <Heading
            fontFamily={"fonts.heading"}
            as="h1"
            size="xl"
            letterSpacing={"tighter"}
            color={textColor}
          >
            &nbsp;Provenance
          </Heading>
        </Flex>
      )}
      <ConnectButton />
    </Flex>
  );
};
