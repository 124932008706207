// safePrint: Prints the message only if the environment is not production.
export const safePrint = (message: any) => {
    if (process.env.REACT_APP_IS_PROD === 'false') {
      console.log(message);
    }
  };
  
  // safeError: Prints the error message in non-production environments, and a safe error message in production.
export const safeError = (error: Error, uniqueErrorIdentifier: string) => {
    if (process.env.REACT_APP_IS_PROD === 'false') {
      console.error(error);
    } else {
      console.error(`ERROR: ${uniqueErrorIdentifier}`);
    }
  };