import React, {useEffect, useState } from "react";
import { ExternalLinkIcon } from '@chakra-ui/icons'
// Import Modal from Chakra UI
import {
    Modal,
    ModalOverlay,
    useDisclosure,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Text,
    Link,
    Button
} from "@chakra-ui/react";
import { FaTwitter, FaDiscord } from 'react-icons/fa';

interface Props {
  openSeaLink: string;
  clearLink: () => void;
}


export const OpenSeaModal = ({ openSeaLink, clearLink }: Props) => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    useEffect(() => {
        if (openSeaLink) {
          onOpen();
        }
    }, [openSeaLink]);

      const handleClose = () => {
        clearLink();
        onClose();
      };

      const createTweet = () => {
        const tweetText = encodeURIComponent(
          `I just minted an AI-generated NFT on @provenance_ai! Explore the future of NFTs and mint your own on Polygon for a few cents!`
        );
        const url = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
          openSeaLink
        )}&text=${tweetText}&hashtags=provai,Provenance,Polygon,NFT,ProvenanceAI`;
        window.open(url, "_blank");
      };

     

    return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={handleClose}>
       <ModalContent background="blue.300">
      <ModalHeader>Generated Images</ModalHeader>
      <ModalCloseButton />
          <ModalBody >
            <Link href={openSeaLink} isExternal>
        Opensea link to your NFT<ExternalLinkIcon mx='2px' />
        <Text fontSize="sm" color="gray.200">Can take up to 10 minutes to load</Text>
        </Link>
      </ModalBody>

      <ModalFooter>
      <Button onClick={createTweet} colorScheme="twitter">
             <FaTwitter size="1.5em" color="white" style={{ marginRight: '10px' }} /> Share
          </Button>
      </ModalFooter>
    </ModalContent>
    </Modal>
    )
    
}