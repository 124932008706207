import React, { useCallback, useRef, useEffect, useState, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import {
    Box, Flex, Text, VStack, Divider, Modal, ModalHeader, ModalCloseButton, ModalBody, ModalOverlay, ModalContent, ModalFooter, Button, Slider, SliderTrack, SliderFilledTrack, SliderThumb, HStack, IconButton,

    Image as ChakraImage
} from '@chakra-ui/react';
import { FaUndo } from 'react-icons/fa';
import Transparency_Grid from '../../images/Transparency_Grid.png';

// variantImageUrl setEditedImageUrl
interface Props {
    setEditedImageUrl: (url: string) => void;
    setEditedBlobData: (blob: Blob | null) => void;
    setVariantImageUrl: (url: string) => void;
    setVariantBlobData: (blob: Blob | null) => void;
    setParentMode: (mode: "default" | "edit" | "variant") => void;
    open: boolean;
    variantImageUrl: string;
    editedImageUrl: string;
    requestClose: () => void;
    parentMode: "default" | "edit" | "variant";
    originalImageUrl: HTMLImageElement | null;
    setOriginalImageUrl: (image: HTMLImageElement | null) => void;
    image: HTMLImageElement | null;
    setImage: (image: HTMLImageElement | null) => void;

}

const DalleEdits: React.FC<Props> = ({image, setImage, originalImageUrl, setOriginalImageUrl, parentMode, variantImageUrl, editedImageUrl, setEditedImageUrl, setEditedBlobData, setVariantImageUrl, setVariantBlobData, open, requestClose, setParentMode }: Props) => {
    const [mode, setMode] = useState<"default" | "edit" | "variant">("default");
    // const [image, setImage] = useState<HTMLImageElement | null>(null);
    const [isOpen, setIsOpen] = useState(false);
    const [isErasing, setIsErasing] = useState(false);
    const [eraserSize, setEraserSize] = useState(10);
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const canvasHistory = useRef<string[]>([]);
    const ref = React.useRef(null);

    // //editedImageUrl


    // LOGIC WHEN PARENT CHANGES OPEN STATE
    useEffect(() => {
        if (open !== undefined) {
            setIsOpen(open);
            if (open) {
                // Load image depending on the mode
                const img = new Image();
                var imageUrl: string;// = mode === "edit" ? editedImageUrl : variantImageUrl;
                if (parentMode === "edit") { // || "default"
                    imageUrl = editedImageUrl;
                } else if (parentMode === "variant") {
                    imageUrl = variantImageUrl;
                } 

                img.src = imageUrl!;
                img.onload = () => setImage(img);
                //img.onload = () => setOriginalImageUrl(img);
                // console.log("useEffect: ", imageUrl);
                setMode("default");
            }
        }
    }, [open, editedImageUrl, variantImageUrl]);


    const handleOpen = () => {
        setIsOpen(true);
    };


    const onDrop = useCallback((acceptedFiles: File[]) => {
        setMode("default");
        const file = acceptedFiles[0];
        const blob = new Blob([file], { type: file.type });
        const url = URL.createObjectURL(blob);

        const img = new Image();
        img.src = url;
        img.onload = () => {
            setImage(img); // Set image in the state when it's loaded
            // if (!originalImageUrl) {
            //     setOriginalImageUrl(img);
            // }
            setOriginalImageUrl(img);
            handleOpen();  // Open the modal
        };

        //setEditedImageUrl(url); // Here we're passing back the image URL to the parent
    }, [setEditedImageUrl, handleOpen]);



    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    useEffect(() => {
        if (!isOpen || !image) return;

        const observer = new MutationObserver(() => {
            const canvas = canvasRef.current;
            if (canvas) {
                const context = canvas.getContext('2d');

                // Set canvas width and height to match image
                canvas.width = image.width;
                canvas.height = image.height;

                context!.clearRect(0, 0, canvas.width, canvas.height);

                context!.drawImage(image, 0, 0, image.width, image.height);
                observer.disconnect();
            }
        });

        observer.observe(document, { childList: true, subtree: true });
    }, [isOpen, image]);

    const eraserCursor = useMemo(() => {
        const offscreenCanvas = document.createElement('canvas');
        offscreenCanvas.width = 40;
        offscreenCanvas.height = 40;

        const ctx = offscreenCanvas.getContext('2d');
        if (!ctx) return '';

        ctx.beginPath();
        ctx.arc(20, 20, eraserSize, 0, 2 * Math.PI, false);
        ctx.fillStyle = 'red';
        ctx.fill();

        return offscreenCanvas.toDataURL();
    }, [eraserSize]);


    useEffect(() => {
        const canvas = canvasRef.current;
        if (!canvas) return;

        canvas.style.cursor = isErasing ? `url('${eraserCursor}') 20 20, auto` : 'default';
    }, [isErasing, eraserCursor]);

    useEffect(() => {
        if (mode !== 'edit' && image) {
            const canvas = canvasRef.current;
            if (canvas) {
                const context = canvas.getContext('2d');
                // Clear the canvas and draw the original image
                context!.clearRect(0, 0, canvas.width, canvas.height);
                context!.drawImage(image, 0, 0, image.width, image.height);
            }
            canvasHistory.current = [];  // Clear the history
        }
    }, [mode, image]);

    const handleMouseDown = (e: React.MouseEvent) => {
        if (mode !== 'edit') return;  // This line disables editing when not in 'edit' mode

        setIsErasing(true);
        const canvas = canvasRef.current;
        const context: any = canvas?.getContext('2d');
        if (context && canvas) {
            canvasHistory.current.push(context.getImageData(0, 0, canvas.width, canvas.height));
        }
        erase(e);
    };

    const undo = () => {
        const canvas = canvasRef.current;
        const context: any = canvas?.getContext('2d');
        if (context && canvasHistory.current.length) {
            const previousState = canvasHistory.current.pop();
            context.putImageData(previousState, 0, 0);
        } else if (!canvasHistory.current.length && context && originalImageUrl) {
            context.clearRect(0, 0, canvas!.width, canvas!.height);
            context.drawImage(originalImageUrl, 0, 0, originalImageUrl.width, originalImageUrl.height);
        }
    };


    const handleMouseMove = (e: React.MouseEvent) => {
        if (isErasing) erase(e);
    };

    const handleMouseUp = () => {
        setIsErasing(false);
    };

    const erase = (e: React.MouseEvent) => {
        const canvas = canvasRef.current;
        const context = canvas?.getContext('2d');
        const rect = canvas?.getBoundingClientRect();

        // compute the scale
        const scaleX = canvas!.width / rect!.width;
        const scaleY = canvas!.height / rect!.height;

        // transform client coordinates to canvas bitmap coordinates
        const x = (e.clientX - (rect?.left || 0)) * scaleX;
        const y = (e.clientY - (rect?.top || 0)) * scaleY;

        context?.beginPath();
        context!.globalCompositeOperation = 'destination-out';
        context?.arc(x, y, eraserSize, 0, Math.PI * 2);
        context?.fill();
        context!.globalCompositeOperation = 'source-over';
    };

    const onClose = (currentMode: "default" | "edit" | "variant") => {
        saveChanges(currentMode);
        setIsOpen(false);
        if (requestClose) requestClose();
    };

    const closeVariant = () => {
        onClose("variant");
    };

    const saveChanges = (currentMode: "default" | "edit" | "variant") => {
        if (canvasRef.current) {
            canvasRef.current.toBlob((blob) => {
                if (blob) {
                    const newUrl = URL.createObjectURL(blob);
                    if (currentMode === "edit") {
                        setEditedImageUrl(newUrl);
                        setEditedBlobData(blob);
                        setParentMode("edit")
                    } else if (currentMode === "variant") {
                        setVariantImageUrl(newUrl);
                        setVariantBlobData(blob);
                        setParentMode("variant")
                    }
                }
            });
        }
    };


    return (
        <VStack width="100%">
            <Flex width={'100%'} align="center">
                <Divider />
                <Text as={'b'} color={'white'} padding="2">OR</Text>
                <Divider />
            </Flex>
            <Box {...getRootProps()} width="100%" border="2px dashed" borderColor="gray.500" py={6} mb={6}>
                <input {...getInputProps()} accept="image/png" />
                <Flex justifyContent="center" alignItems="center" height="100%">
                    {isDragActive ?
                        <Text color="white">{parentMode !== "default" ? "Upload New Image" : "Drop the image here ..."}</Text> :
                        <Text color="white">{parentMode !== "default" ? "Upload New Image" : "Upload an Image (.png) to Edit Here "}</Text>
                    }
                </Flex>
            </Box>
            {isOpen && image && (
                <Modal isOpen={true} onClose={() => onClose(mode)} size="full">
                    <ModalOverlay />
                    <ModalContent ref={ref} background="gray.800" width="80%" height="80%">
                        <ModalHeader color={'white'}>Image Editor</ModalHeader>
                        <ModalCloseButton color={'white'} />
                        <ModalBody overflowY="auto" display="flex" alignItems="center" justifyContent="center" position="relative">
                            <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <ChakraImage src={Transparency_Grid} alt="transparency grid" position="absolute" zIndex="-1" width="100%" height="100%" objectFit="contain" />
                                <canvas
                                    ref={canvasRef}
                                    onMouseDown={handleMouseDown}
                                    onMouseMove={handleMouseMove}
                                    onMouseUp={handleMouseUp}
                                    onMouseLeave={handleMouseUp}
                                    style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
                                />
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <HStack width="full" justifyContent="space-between">
                                {mode === "edit" && (
                                    <HStack width="full" spacing={2} justifyContent="left">
                                        <Text as={'b'} color={'white'}>Eraser Size</Text>
                                        <Slider
                                            width="25%"
                                            min={5}
                                            max={30}
                                            value={eraserSize}
                                            onChange={(value) => setEraserSize(value)}
                                        >
                                            <SliderTrack>
                                                <SliderFilledTrack />
                                            </SliderTrack>
                                            <SliderThumb boxSize={6}>
                                                <Box color="tomato" />
                                            </SliderThumb>
                                        </Slider>

                                        <IconButton icon={<FaUndo />} onClick={undo} aria-label="Undo Edit" />
                                        <Button colorScheme="blue" mr={3} onClick={() => onClose(mode)}>Done</Button>
                                    </HStack>

                                )}
                                {mode === "default" &&
                                    <HStack justifyContent="left">
                                        <Button colorScheme="blue" mr={3} onClick={() => setMode("edit")}>Editor</Button>
                                        <Button colorScheme="green" mr={3} onClick={() => closeVariant()}>Create Variant</Button>
                                    </HStack>
                                }
                                {mode === "default" &&
                                    <HStack justifyContent="right">
                                        <input
                                            id="upload-new-image"
                                            type="file"
                                            accept="image/png"
                                            style={{ display: "none" }}
                                            onChange={event => {
                                                const file = event.target.files![0];
                                                onDrop([file]);
                                            }}
                                        />
                                        <Button colorScheme="blue" onClick={() => {
                                            document.getElementById('upload-new-image')!.click();
                                        }}> Upload New Image </Button>
                                    </HStack>
                                }
                                {mode !== "default" && <Button colorScheme="blue" onClick={() => setMode("default")}>Go Back</Button>}
                            </HStack>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            )}
        </VStack>
    );

};

export default DalleEdits;
