import axios from "axios";
import { Dimension, Steps, pricingTable } from "../components/body/DataStructs"
import { safePrint } from "./errorHandling";

  // Function to call CoinGecko API to get ETH price
  export const getMATICPriceFromCoinGecko = async () => {
    try {
      const response = await axios.get('https://api.coingecko.com/api/v3/simple/price?ids=matic-network&vs_currencies=usd');
      const price = response.data['matic-network'].usd;
      console.log('MATIC price from CoinGecko:', price);
      return price;
    } catch (error) {
      console.error('Error getting MATIC price from CoinGecko:', error);
      return null;
    }
  };

  export const getMATICPriceFromEtherscan = async () => {
    return null;
    // try {
    //   const apiKey = 'YOUR_ETHERSCAN_API_KEY';
    //   const response = await axios.get(`https://api.etherscan.io/api?module=stats&action=tokenprice&contractaddress=0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0&apikey=${apiKey}`);
    //   const priceInUSD = Number(response.data.result.usd) / 1e18;
    //   return priceInUSD;
    // } catch (error) {
    //   console.error('Error getting MATIC price from Etherscan:', error);
    //   return null;
    // }
  };

  export const StableDiffusionCalcCost = (
    dimension: Dimension,
    steps: Steps
  ) => {
    console.log(`SD dimension: ${dimension}`)
    const costPerImage = pricingTable[steps][dimension];
    safePrint(`SD costPerImage: ${costPerImage}`)
    return costPerImage;
  };