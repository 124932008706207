export const items = [
    {
      key: 'Basketball_Nebula.png',
      title: "Basketball Nebula",
      description: "An expressive oil painting of a basketball player dunking, depicted as an explosion of a nebula",
    },
    {
      key: 'cyberpunk_samurai.png',
      title: "cyberpunk samurai",
      description: "Cyberpunk themed samurai wearing a traditional style mask wielding a futuristic kitana. realistic digital art",
    },
    {
      key: 'Dreamland.png',
      title: "Dreamland",
      description: "Dreamlike surreal landscape, island suspended in space with galaxy in background",
    },
    {
      key: 'astronaut.png',
      title: "3D render of an Astronaut",
      description: "A 3D render of an astronaut walking in a green desert",
    },
    {
        key: 'Cybernetic_Ninja.png',
        title: "Cybernetic Ninja",
        description: "A close-up headshot of a ninja with colorful lighting from the future cybernetic style digital art",
      },
      {
        key: 'Wizard-Mouse.png',
        title: "Wizard Mouse",
        description: "Wizard mouse in laboratory, pixel art",
      },
      {
        key: 'Cyberpunk-NYC-Skyline.png',
        title: "Cyberpunk NYC Skyline",
        description: "futuristic nighttime cyberpunk New York City skyline landscape vista photography by Carr Clifton & Galen Rowell, 16K resolution, Landscape veduta photo by Dustin Lefevre & tdraw, 8k resolution, detailed landscape painting by Ivan Shishkin, DeviantArt, Flickr, rendered in Enscape, Miyazaki, Nausicaa Ghibli, Breath of The Wild, 4k detailed post processing, atmospheric, hyper realistic, 8k, epic composition, cinematic, artstation",
      },
      {
        key: 'Rick-and-Morty.png',
        title: "Rick and Morty",
        description: "Rick and Morty stepping out of a green portal onto an alien planet, as digital art",
      },
      {
        key: 'South-Park.png',
        title: "South Park",
        description: "Cartman from South Park holding a bucket of KFC chicken while standing at the bus stop as digital art",
      },
  ];